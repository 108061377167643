import React,{Fragment,useState} from 'react';
import { Form, Formik } from 'formik';
import FormikControl from '../../common/formik/FormikControl';
import { object, string } from 'yup';
import Loader from "react-loader-spinner";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import * as query_string from "query-string";
import {useHistory } from "react-router-dom";
import AuthService from '../../../services/auth/AuthService';




const Login = () => {

 
    // const location = useLocation();
    const history = useHistory();

    const navigateToUrl = (url, e = null) => {
        if(e) {
            e.preventDefault();
        }
        history.push(url);
    };

    const initialValues = {
        email : "",
        password: ""
    }

    const validationSchema = object({
        email:string().email().required(),
        password:string().required(),
        
      });

      const [isSubmitting, setIsSubmitting] = useState(false);
      const [showSubmitLoader, setShowSubmitLoader] = useState(false)

  

    const saveToken = (data)=> {
        localStorage.setItem(process.env.REACT_APP_TOKEN, data.token);
        localStorage.setItem(process.env.REACT_APP_USER, JSON.stringify(data));
        // console.log('Storage Set ', process.env.REACT_APP_USER);
  
      }
    const setCookie = (name,value,days) => {
          let expires = "";
          if (days) {
              let date = new Date();
              date.setTime(date.getTime() + (days*24*60*60*1000));
              expires = "; expires=" + date.toUTCString();
          }
          document.cookie = name + "=" + (value || "")  + expires + "; path=/";
          // console.log('Cookies Set ', name);
      }
    const  eraseCookie = (name) => {
          document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      }

    
      
  const handleSubmit = async (data) => {
    // data.preventDefault();

      eraseCookie(process.env.REACT_APP_TOKEN);
      eraseCookie(process.env.REACT_APP_USER);
      setShowSubmitLoader(true);
      setIsSubmitting(true);
    //   setState({ showSubmitLoader: true, serverMessage: "" });

    const authService = new AuthService();
    try {
      const response = await authService.loginUser(data);
    //   console.log('RESPONSE ', response.status);
      if (response.status === "SUCCESS") {
        saveToken(response.data);
        setCookie(process.env.REACT_APP_TOKEN, response.data.token, 30);
        setCookie(process.env.REACT_APP_USER, JSON.stringify(response.data), 30);
        setShowSubmitLoader(false);
        setIsSubmitting(false);
        toast.success(response.msg || 'Login successful!');
        
        navigateToUrl('/dashboard/')
        
         
      } else {
        
              toast.error(response.msg || 'Login failed!');
              setShowSubmitLoader(false);
              setIsSubmitting(false);
              eraseCookie(process.env.REACT_APP_TOKEN);
              eraseCookie(process.env.REACT_APP_USER);
      
      }
    } catch (error) {
        eraseCookie(process.env.REACT_APP_TOKEN);
        eraseCookie(process.env.REACT_APP_USER);
        console.log('ERROR ', error);
      toast.error(error.msg || 'Error: Unable to login at the moment!');
      setShowSubmitLoader(false);
      setIsSubmitting(false);
    }
  };
  


    return(
        <Fragment>
            <div className=" login-card col-md-3">
                <h3 className="mb-5">ADMIN LOGIN</h3>
                <div className="card p-5">
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) =>{handleSubmit(values)}} >
                            
                            <Form>
                                    <FormikControl
                                        control='input'
                                        name= 'email'
                                        label='Email Address'
                                        type="email"
                                        id="m-input"
                                    />
                                    <br/>
            
                                    <FormikControl
                                        control='input'
                                        name= 'password'
                                        label='Password'
                                        type="password"
                                        id="m-input"
                                    />
            
            
                                            <button type='submit' 
                                                className='btn btn-primary w-100 mt-3  rounded'
                                                disabled={isSubmitting}
                                                >
            
                                                    {showSubmitLoader? (
                                                         <Loader
                                                         type="ThreeDots"
                                                         color="white"
                                                         height={30}
                                                         width={50}
                                                         timeout={10000000000000} //3 secs
                                                       />
                                                    )
                                                    :(
                                                        "Login"
                                                    )}
                                                </button>
                            </Form>
                        </Formik>  
                        
                </div>
           </div> 
       </Fragment>    
    );

}

export default Login;