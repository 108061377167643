import React, { Fragment, useState } from 'react';
import ProgressBar from '../../../../common/ProgressBar';
import CompanyProfile from './companyProfile/CompanyProfileForm';
import withDashboard from '../../../hoc/withDashboard';

const SetupStore = (props) => {
  const [_progress, _setProgress] = useState({width: '1%'});
    const handleChange = (e) => {
        console.log('Event Received! ', e, _progress);
        _setProgress({width: e.width});
        console.log('Progress ', _progress);
    };
  return (
    <Fragment>
      <div className='container setup-store'>
        <div className='row mb-4'>
          <div className='col-sm-6 col-md-6'>
            <div className='main-header'>
              <h2>Let's setup your store</h2>
            </div>
          </div>
          <div className='col-sm-6 col-md-6'>
            <div className='sub-header-4'>
              <h4>{_progress.width || '1%'} completed</h4>
            </div>
            <ProgressBar progress={_progress || {width: '1%'}} />
          </div>
        </div>

        <br/>

        <div className='row'>
          <div className='col-lg-12'>
            <CompanyProfile progressHandler = {handleChange} {...props} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withDashboard(SetupStore);
