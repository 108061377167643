import React, {useState} from 'react';
import { Form, Formik } from 'formik';

import FormikControl from '../../../common/formik/FormikControl';

import RandomService from "../../../../utils/randomServices";
import { object, string} from 'yup';
import UserService from "../../../../services/user/UserService";
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';


const ChangePassword = () => {

    const history = useHistory();
    // async function getCurrentUser() {
    //     const response = await RandomService.getAuthUser();
    //     return response;

    //   }


    //   React.useMemo(
    //     () => {
    //       getCurrentUser().then();

        

    //     },
    //     // eslint-disable-next-line
    //     []
    //   );

    const [loading, setLoading] = useState(false);

    
   

  
  
    const validationSchema = object({
        currentPassword: string().required('Your old password is required'),
        newPassword: string().required('Your new password is required').matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
          ),
        confirmpassword: string().test('passwords-match', 'Passwords must match', function(value){
            return this.parent.newPassword === value
          })

      });

      
     
      const changePassword = async(data)=>{
        delete data.confirmpassword;
        setLoading(true);
        const userdetails = JSON.parse(localStorage.getItem(process.env.REACT_APP_USER));
        const userService = new UserService();
        const response = await userService.changePassword(data,userdetails.user._id);

        console.log(response);
        if (response && response.status === 'SUCCESS') 
        {
            toast.success("Password changed successfully!");
            setLoading(false);
            toast.success("Logging Out...");
            RandomService.eraseCookie(process.env.REACT_APP_USER);
            RandomService.eraseCookie(process.env.REACT_APP_TOKEN);
            history.push('/');
        } 
        else 
        {
        toast.error(response.msg || 'Unable to change password!');
        setLoading(false);
        }
      }

      const initialValues = {
        currentPassword:'',
        newPassword:'',
        confirmpassword:'',
      };

    
     
     return (
          
          
          <div>            

{/* password reset */}

<br/><br/><br/>
            <div className="password-reset mt-5 px-4">
                <h4 className="text-dark px-4 mt-5">Password Reset</h4>
                <Formik initialValues={initialValues} onSubmit={(values) =>{changePassword(values)}} validationSchema={validationSchema} >
                            <Form>
                                   <div className="col-md-5 mt-4">
                                        <FormikControl
                                            control='input'
                                            type='password'
                                            name= 'currentPassword'
                                            label='Current Password'
                                            />

                                        <FormikControl
                                            control='input'
                                            type='password'
                                            name= 'newPassword'
                                            label='New Password'

                                            />  
         
                                        <FormikControl
                                            control='input'
                                            name= 'confirmpassword'
                                            type='password'
                                            label='Confirm Password'
                                           
                                            /> 

                                        <button
                                        type='submit'
                                        className='btn btn-primary text-big float-right mt-3 w-100 rounded'
                                        >
                                         {loading ? (
                                            <Loader
                                                type='ThreeDots'
                                                color='white'
                                                height={30}
                                                width={50}
                                                timeout={10000000000000} //3 secs
                                            />
                                            ) : (
                                            'Change Password'
                                            )}
                                        </button>
                                   </div>

                               
                            </Form>
                        </Formik>
                </div>
                    </div>
           
        );
   
}

// export default ListOrders;

export default ChangePassword;
