import axios from 'axios';
import setAuthTokenHeader from '../../utils/setAuthTokenHeader';

// Note all apiService call accepts url and optional data

export default class UserService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_USER_SERVICE;
    this.setAuthTokenHeader = setAuthTokenHeader;
    this.token = true; //localStorage.getItem("token");
    this.config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
  }
    // async getStates(country){
    //     try {
    //         const response = await axios.get(
    //             `${this.baseUrl}/utils/country-state-city?countryCode=${country}`
    //         );
    //         return response.data;
    //     } catch (error) {
    //         return error.message;
    //     }
    // }
    // async getCities(state){
    //     try {
    //         const response = await axios.get(
    //             `${this.baseUrl}/utils/country-state-city?stateCode=${state}`
    //         );
    //         return response.data;
    //     } catch (error) {
    //         return error.message;
    //     }
    // };

    async changePassword (data,userId){
      if (this.token) {
        this.setAuthTokenHeader();


    }
      try {
        const response = await axios.put(
          `${this.baseUrl}/user/change-password/${userId}`,
          data
        );
        return response.data;
      } catch (error) {
        return error;
      }
    };

    async registerUser (data) {
        if (this.token) {
            this.setAuthTokenHeader();


        }
        try {
            const response = await axios.post(`${this.baseUrl}/user/admin/onboard-user`, data);
            return response.data;
        } catch (error) {
            if (error && error.response && error.response.data) {
                return error.response && error.response.data;
            } else if (error && error.response) {
                return error.response && error.response.data;
            } else {
                return { msg: "Network or internet error", status: "FAILURE" };

            }
        }
    };

    async getUser (userId){
        // console.log("store id", storeId);
        if (this.token) {
          this.setAuthTokenHeader(this.token);
        }
        try {
          const response = await axios.get(
            `${this.baseUrl}/user/profile/${userId}`
          );
          return response.data;
        } catch (error) {
          if(error && error.response && error.response.data) {
                return error.response && error.response.data;
            } else if(error && error.response) {
                return error.response && error.response.data;
            } else {
                return {msg: "Network or internet error", status: "FAILURE"};
    
            }
        }
      }

      
    async getUserCountBasedOnStatus (status){
      // console.log("store id", storeId);
      if (this.token) {
        this.setAuthTokenHeader(this.token);
      }
      try {
        const response = await axios.get(
          `${this.baseUrl}/user/count?status=${status}`
        );
        return response.data;
      } catch (error) {
        if(error && error.response && error.response.data) {
              return error.response && error.response.data;
          } else if(error && error.response) {
              return error.response && error.response.data;
          } else {
              return {msg: "Network or internet error", status: "FAILURE"};
  
          }
      }
    }

    async getUserCount (){
      // console.log("store id", storeId);
      if (this.token) {
        this.setAuthTokenHeader(this.token);
      }
      try {
        const response = await axios.get(
          `${this.baseUrl}/user/count`
        );
        return response.data;
      } catch (error) {
        if(error && error.response && error.response.data) {
              return error.response && error.response.data;
          } else if(error && error.response) {
              return error.response && error.response.data;
          } else {
              return {msg: "Network or internet error", status: "FAILURE"};
  
          }
      }
    }

  async getAllUsers () {
    // console.log("store id", storeId);
    if (this.token) {
      this.setAuthTokenHeader();
    }
    try {
      // ?limit=${limit}&page=${page}

      const response = await axios.get(`${this.baseUrl}/user/list?limit=9000&sortBy=createdAt:desc`);

      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internet error', status: 'FAILURE' };
      }
    }
  }
  }
