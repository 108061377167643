import React, { Component, Fragment } from 'reactn';
import UploadService from './../../../utils/uploadService';
import RandomService from '../../../utils/randomServices';
import { toast } from 'react-toastify';

import './style.css';
import * as $ from 'jquery';
// import { Croppie } from 'croppie/croppie';
let Croppie = require('croppie');
// let uploadElement;
let vanilla = null;

class Croppiee extends Component {
  /*
    @Input() croppieData: any;
    @Output() updateUI: EventEmitter<any> = new EventEmitter();
    @Output() showLoad: EventEmitter<any> = new EventEmitter();*/

  constructor(props) {
    super();
    this.state = {
      activateCroppie: false,
      imageChanged: false,
      selectedFiles: null,
      imageUploaded: false,
      uploadElement: null,
      fileKeeper: null,
      interval: null,
      imageLoader: false,
    };
  }
  componentDidMount() {
    // this.props.croppieData.config.profile = this.props.croppieData.config.profile || false;
  }
  startInitProcess() {
    this.setState({ activateCroppie: true });
    setTimeout(() => {
      this.initCroppie();
      $('.cr-slider').addClass('makeWhite');
      $('.makeWhite').attr('style', 'background-color: #ffffff!important');
    }, 100);
  }
  rotateLeft = () => {
    vanilla.rotate(90);
    this.setState({ imageChanged: true });
  };
  rotateRight = () => {
    vanilla.rotate(-90);
    this.imageChanged = true;
  };
  result = () => {
    this.props.showLoad('showLoading');
    vanilla.result('canvas', 'original', 'png', 1).then((image) => {
      toast.info('Upload in progress...');
      UploadService.uploadB64(
        image,
        this.props.croppieData.data,
        this.state.fileKeeper.name,
        (data) => {
          this.props.updateUI({
            image,
            item: this.props.croppieData.key,
            userObject: data,
          });
          toast.info('Product cover image uploaded!');
        },
        this.props.croppieData.key
      );
    });
  }; /*
    getCroppieImage = async (quality, numCalls) => {
        return vanilla.result({
            type: 'blob',
            size: {
                width: 500,
                height: 500
            },
            format: 'jpeg',
            quality: quality,
            circle: false
        })
            .then((imageDataBlob) => {
                // If image size is still too large just call result again with lower quality
                if (imageDataBlob.size > 500000 && numCalls < 3) {
                    quality = quality - 0.1;
                    numCalls++;
                    return this.getCroppieImage(quality, numCalls);
                } else {
                    return imageDataBlob;
                }
            });
    }*/
  initCroppie = () => {
    const el = document.getElementById(this.props.croppieData.id);
    const Options = {
      viewport: {
        width: this.props.croppieData.config.vwidth,
        height: this.props.croppieData.config.vheight,
        type: this.props.croppieData.config.type || 'square',
      },
      boundary: {
        width: this.props.croppieData.config.fwidth,
        height: this.props.croppieData.config.fheight,
      },
      showZoomer: true,
      enableOrientation: true,
      enableResize: this.props.croppieData.config.enableResize,
      mouseWheelZoom: 'ctrl',
    };
    vanilla = new Croppie(el, Options);
    vanilla.bind({
      url: this.state.imageLoader,
      orientation: 1,
    });
  }; /*
    initFileUpload() {
        uploadElement = document.getElementById('vanilla-demo');
        uploadElement.addEventListener('change', this.onFileUpload.bind(this));
    }*/
  /*
    onFileUpload() {
        if (uploadElement.files && uploadElement.files.length > 0) {
            this.setState({imageChanged: true});

            const file = uploadElement.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = ((event) => {
                this.state.vanilla.bind({
                    url: event.target.result
                });
            });
        }
    }*/
  processSelectedFile(event) {
    // this.state.imageUploaded = false;
    this.setState({ imageUploaded: false });
    const selectedFiles = event.target.files;
    const reader = new FileReader();
    const file = selectedFiles[0];

    this.setState({ fileKeeper: selectedFiles[0] });

    if (file) {
      reader.onloadend = () => {
        // this.state.imageLoader = reader.result;
        this.setState({ imageLoader: reader.result });
        this.setState({ imageUploaded: true });
        this.startInitProcess();
      };
      reader.readAsDataURL(file);
    }
  }
  removeImageAndRestorePicker = () => {
    this.setState({ activateCroppie: false });
    if (this.props.croppieData.data.coverImage) {
      const imageInFireBase = this.props.croppieData.data.coverImage.includes(
        'https://firebasestorage.googleapis.com/v0/b/min-user-portal.appspot.com/o/'
      );
      if (imageInFireBase) {
        this.deleteFromFireBase(imageInFireBase);
      }
    }
  };

  // image_99.12349834184657?alt=media&token=e6bbfeab-f3ef-4a39-bdda-84f74e82b70e
  deleteFromFireBase(file) {
    const imageName = file.split(
      'https://firebasestorage.googleapis.com/v0/b/min-user-portal.appspot.com/o/minng_files%2F'
    )[1];
    const image = imageName.split('?alt=');

    RandomService.deleteImages(image[0]);
  }

  render() {
    return (
      <Fragment>
        <div className='container'>
          <div className='row'>
            {!this.state.activateCroppie ? (
              <div className='col-md-12 col-sm-12'>
                <div className='dropbox'>
                  <input
                    type='file'
                    accept='image/*'
                    className='input-file'
                    name='productImage'
                    onChange={(event) => this.processSelectedFile(event)}
                  />
                  <div
                    style={{ textAlign: 'center', fontSize: '17px' }}
                    className='mt-1'
                  >
                    <div>
                      <span className='text-center'>
                        <i className='fa fa-upload fa-4x'></i>
                      </span>
                      <br />
                      <p>
                        <small>
                          Drag and Drop Image here or click to browse
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          <div className='row'>
            {this.state.activateCroppie ? (
              <div className='col-md-12 col-sm-12'>
                {this.state.imageUploaded ? (
                  <div>
                    <span className='text-success'>
                      This is product featured image, ensure it's clear and well
                      represent the product
                    </span>
                    <div id={this.props.croppieData.id}></div>

                    {!this.props.croppieData.config.profile ? (
                      <div className='container croppie-controls w-100 pt-3'>
                        <div className='row'>
                          <div className='col-sm-12 col-md-3 mt-3'>
                            <span className='' onClick={this.rotateLeft}>
                              <button type='button' className='btn btn-sm'>
                                <i className='fa fa-rotate-left'></i>{' '}
                                &nbsp;Rotate left
                              </button>
                            </span>
                          </div>
                          <div className='col-sm-12 col-md-3  mt-3'>
                            <span className='' onClick={this.rotateRight}>
                              <button type='button' className='btn btn-sm'>
                                <i className='fa fa-rotate-right'></i> &nbsp;
                                Rotate right
                              </button>
                            </span>
                          </div>
                          <div className='col-sm-12 col-md-3  mt-3'>
                            <span onClick={this.result}>
                              <button
                                type='button'
                                className='btn btn-sm btn-success'
                              >
                                Finish Cropping&nbsp;&nbsp;
                                <i className='fa fa-check'></i>
                              </button>
                            </span>
                          </div>
                          <div className='col-sm-12 col-md-3  mt-3'>
                            <span>
                              <button
                                type='button'
                                className='btn btn-sm btn-danger'
                                onClick={() =>
                                  this.removeImageAndRestorePicker()
                                }
                              >
                                Cancel&nbsp;&nbsp;
                                <i className='fa fa-remove'></i>
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </Fragment>
    );
  }
}
export default Croppiee;
