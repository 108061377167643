import React , {useState,useEffect} from 'react';
// import { Link } from 'react-router-dom';
import withDashboard from '../../hoc/withDashboard';
import {toast} from 'react-toastify';
import Loader from "react-loader-spinner";
import {useHistory} from 'react-router-dom';
import ProductService from '../../../../services/product/ProductService';

export const ProductOverview = () => {
  const history = useHistory();
 
  const [products, setProducts] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchProductCount = async () =>{
    setLoading(true);

  const productService = new ProductService();
  const response = await productService.getProductCount();

    try {
    if (response && response.status === 'SUCCESS'){
        setProducts(response.data);
        setLoading(false);
    } else {
      toast.error(response.msg || 'Unable to list users!');
      setLoading(false);
    }
  } catch (error) {
    toast.error(error.message || 'Unable to register at the moment!');
  }
};

const [onlineProduct, setOnlineProduct] = useState("");

  const fetchOnlineProductsCount = async () =>{
    setLoading(true);

    const productService = new ProductService();
    const response = await productService.getProductCountBasedOnStatus("ONLINE");

    try {
    if (response && response.status === 'SUCCESS'){
        setOnlineProduct(response.data);
    } else {
      toast.error(response.msg || 'Unable to list users!');
    }
  } catch (error) {
    toast.error(error.message || 'Unable to register at the moment!');
  }
};

const [offlineProduct, setOfflineProduct] = useState("");

  const fetchOfflineProductsCount = async () =>{
    setLoading(true);

    const productService = new ProductService();
    const response = await productService.getProductCountBasedOnStatus("PENDING");

    try {
    if (response && response.status === 'SUCCESS'){
        setOfflineProduct(response.data);
    } else {
      toast.error(response.msg || 'Unable to list users!');
    }
  } catch (error) {
    toast.error(error.message || 'Unable to register at the moment!');
  }
};


const [mostDemandedProduct, setMostDemandedProduct] = useState([]);

  const fetchMostDemandedProduct = async () =>{
    setLoading(true);

    const productService = new ProductService();
    const response = await productService.getMostDemandedProducts();

    try {
    if (response && response.status === 'SUCCESS'){
      // console.log("demand: " + JSON.stringify(response.data));
        setMostDemandedProduct(response.data);
    } else {
      toast.error(response.msg || 'Unable to list users!');
    }
  } catch (error) {
    toast.error(error.message || 'Unable to register at the moment!');
  }
};



useEffect(() => {
  fetchProductCount().then();
  fetchOnlineProductsCount().then();
  fetchOfflineProductsCount().then();
  fetchMostDemandedProduct().then();

}, []);

const viewProductDetails = (productId)=> {
  history.push('/dashboard/products/product-details/' + productId);

}

  return (
    <div className="dashboard">
     
     {!loading? (
       
        <div className="card p-5">

        <h3 className="h3 text-dark mb-5">Min.ng admin</h3>
        <div className="overview">
            <h3 className="text-dark ml-n4 mb-5">Product Overview</h3>
          <div className="row">
              <div className="col-md-2 mr-5 mt-3 m-single click">
                <div className="d-flex">
                    <div className="report">
                        <p>Total products</p>
                        <p><b>{products}</b></p>
                    </div>
                </div>
              </div>

              <div className="col-md-2 mr-5 mt-3 m-single">
                <div className="d-flex">
                    <div className="report">
                        <p>Online Products</p>
                        <p><b>{onlineProduct}</b></p>
                    </div>
                </div>
              </div>

              <div className="col-md-2 mr-5 mt-3 m-single">
                <div className="d-flex">
                    <div className="report">
                        <p>Offline Products </p>
                        <p><b>{offlineProduct}</b></p>
                    </div>
                </div>
              </div>
            

     
          
    
     
  </div>


</div>


      <br/><br/>
     <div className="row">
        <div className="col-md-6 mb-4">
              <div className="overview">
                      <h3 className="text-dark ml-n4 mb-5">Most demanded products</h3>

                {mostDemandedProduct.map((product,index) => (
                        <div className="product-card mt-3" key={index}>
                              <div className="clearfix">
                                 <p className="float-left product-name">{product.productName}</p>
                                  <p className="float-right link" onClick={() => viewProductDetails(product._id)}><b>View details</b></p> 
                              </div>
                              <p className="demand"><b>{product.unit_sold} demands</b></p>
                        </div>
                    

                ))}
                       
              
              
                </div>
        </div>


        <div className="col-md-6">
              <div className="overview">
                      <h3 className="text-dark ml-n4 mb-5">Products with highest revenue</h3>
                    
                      
              
              
                </div>
        </div>
     </div>


</div>
     ):(
              <Loader
              type="BallTriangle"
              height={100}
              width={100}
              timeout={3000} //3 secs
              className="loader"
              /> 
     )}
      
    </div>
  );
};

export default withDashboard(ProductOverview);