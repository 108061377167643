import React from 'react';
import PropTypes from 'prop-types';
const ProgressBar = (props) => {
  const { progress } = props;
  return (
    <div className='progress' style={{ height: '.5em' }}>
      <div
        className='progress-bar bg-warning'
        role='progressbar'
        style={progress}
        aria-valuenow={progress.width}
        aria-valuemin='0'
        aria-valuemax='100'
      ></div>
    </div>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.object.isRequired,
};
export default ProgressBar;
