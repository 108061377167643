import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
import TextError from './TextError';

const CheckboxGroup = (props) => {
  const { label, name } = props;
  return (
    <div className='form-group'>
      {label && <label>{label}</label>}
      {props.children}
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

CheckboxGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default CheckboxGroup;
