import React , {useState,useEffect} from 'react';
import UserService from '../../../../services/user/UserService';
// import { Link } from 'react-router-dom';
import withDashboard from '../../hoc/withDashboard';
import {toast} from 'react-toastify';
import Loader from "react-loader-spinner";
// import {useHistory} from 'react-router-dom';
import OrderService from '../../../../services/order/OrderService';
import ListUsers from './ListUsers';

export const UserOverview = () => {
 
  const [users, setUsers] = useState("");
  const [loading, setLoading] = useState(false);

  // get list of users
  const fetchUsersCount = async () =>{
    setLoading(true);

  const userService = new UserService();
  const response = await userService.getUserCount();

    try {
    if (response && response.status === 'SUCCESS'){
        setUsers(response.data);
        setLoading(false);
    } else {
      toast.error(response.msg || 'Unable to list users!');
      setLoading(false);
    }
  } catch (error) {
    toast.error(error.message || 'Unable to register at the moment!');
  }
};

const [activeUsers, setActiveUsers] = useState("");

  // get list of users
  const fetchActiveUsersCount = async () =>{
    setLoading(true);

  const userService = new UserService();
  const response = await userService.getUserCountBasedOnStatus("ACTIVE");

    try {
    if (response && response.status === 'SUCCESS'){
        setActiveUsers(response.data);
    } else {
      toast.error(response.msg || 'Unable to list users!');
    }
  } catch (error) {
    toast.error(error.message || 'Unable to register at the moment!');
  }
};


const [Inquiries, setInquiries] = useState("");
const fetchInquiryCount = async () =>{

  const orderService = new OrderService();
  const response = await orderService.getInquiryCount();
try{
  if (response && response.status === 'SUCCESS'){
      setInquiries(response.data);
      // setLoading(false);
  } else {
      // setLoading(false);
  }

  }catch (error) {
      setLoading(false);
  }
}


useEffect(() => {
  fetchUsersCount().then();
  fetchActiveUsersCount().then();
  fetchInquiryCount().then();

}, []);


  return (
    <div className="dashboard">
     
     {!loading? (
       
<div className="card p-5">

<h3 className="h3 text-dark mb-5">Min.ng admin</h3>
<div className="overview">
    <h3 className="text-dark ml-n4 mb-5">User Overview</h3>
  <div className="row">
      <div className="col-md-2 mr-5 mt-3 m-single click" >
        <div className="d-flex">
             <div className="report">
                 <p>All Users</p>
                 <p><b>{users}</b></p>
             </div>
        </div>
      </div>

      <div className="col-md-2 mr-5 mt-3 m-single">
        <div className="d-flex">
             <div className="report">
                 <p>Active Users</p>
                 <p><b>{activeUsers}</b></p>
             </div>
        </div>
      </div>

      <div className="col-md-2 mr-5 mt-3 m-single">
        <div className="d-flex">
             <div className="report">
                 <p>All Inquiry</p>
                 <p><b>{Inquiries}</b></p>
             </div>
        </div>
      </div>
    

     
          
    
     
  </div>

  {/* <Link to="/dashboard/user/list-users/" className="h4 float-right">View all</Link> */}

</div>

<br/><br/><br/>
<ListUsers/>
</div>
     ):(
              <Loader
              type="BallTriangle"
              height={100}
              width={100}
              timeout={3000} //3 secs
              className="loader"
              /> 
     )}
      
    </div>
  );
};

export default withDashboard(UserOverview);