import React from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';

const Textarea = (props) => {
  const { name, label, ...rest } = props;
  return (
    <div className='form-group'>
      <label htmlFor={name}>{label}</label>
      <Field
        as='textarea'
        id={name}
        name={name}
        className='form-control'
        {...rest}
      />
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
};

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default Textarea;
