import React, { Component } from 'reactn';
import { toast } from "react-toastify";
import Validator from "../../validator";
import YearPicker from "react-year-picker";
import StoreService from "../../../../../../services/store/StoreService";

class CompanyProfileFirstStep extends Component {

constructor() {
    super();
    this.state = { errorMessages: {}, disableButton: true,
        isSubmitting: false,
        companyName: "",
        yearEstablished: "",
        employeeNumber: "",
        businessType: "",
        aboutUs: "" };

       
}
componentDidMount() {
    console.log('STORE For Edition ', this.props.store);
    if(this.global.stageOneDone){
        this.setState({
            companyName: this.global.companyName,
            yearEstablished: this.global.yearEstablished,
            employeeNumber: this.global.employeeNumber,
            businessType: this.global.businessType,
            aboutUs: this.global.aboutUs
        });
    } else if (this.props.store._id) {
        this.setState({
            companyName: this.props.store.companyName,
            yearEstablished: this.props.store.yearEstablished,
            employeeNumber: this.props.store.employeeNumber,
            businessType: this.props.store.businessType,
            aboutUs: this.props.store.aboutUs
        });
    }
}
    yearOptions = [
        {
            key: '2010',
            value: '2010',
        },
        {
            key: '2011',
            value: '2011',
        },
        {
            key: '2012',
            value: '2012',
        },
    ];

    employeeOptions = [
        {
            key: '1-10',
            value: '1-10',
        },
        {
            key: '11-20',
            value: '11-20',
        },
        {
            key: '21-50',
            value: '21-50',
        },
        {
            key: '51 and above',
            value: '51 and above',
        },
    ];

    businessOptions = [
        {
            key: 'Manufacturing',
            value: 'manufacturing',
        },
        {
            key: 'Ecommerce',
            value: 'ecommerce',
        },
        {
            key: 'Construction',
            value: 'construction',
        },
    ];
    storeService = new StoreService();

    handleChange = (event) => {

     
        this.setGlobal({ [event.target.name]: event.target.value });
        this.setState({ [event.target.name]: event.target.value });
        
    };

    handleYear = (event) => {

     
        this.setGlobal({ "yearEstablished": event});
        this.setState({ "yearEstablished": event});
        
    };
   
    handleSubmit = async (event) => {
        event.preventDefault();
        if (this.props.store._id) {
            this.setState({isSubmitting: true});
            this.props.showProgress(true);
            const response = await this.storeService.updateStore(this.props.store._id, {
                companyName: this.state.companyName, yearEstablished: this.state.yearEstablished, employeeNumber: this.state.employeeNumber,
                businessType: this.state.businessType, aboutUs: this.state.aboutUs
            });
            if (response.status === 'SUCCESS') {
                this.props.setActiveStep(2);
                this.props.showProgress(false);
                this.setState({isSubmitting: false});
            } else {
                this.props.showProgress(false);
                this.setState({isSubmitting: false});
            }
        } else {
            this.props.showProgress(true);
            // console.log('My values ', values);
            if (!this.state.companyName) {
                this.props.showProgress(false);
                return toast.error("Company Name is required!")
            } else if (!this.state.aboutUs) {
                this.props.showProgress(false);
                return toast.error("Tell us a bit about your company!");
            } else if (!this.state.yearEstablished || !this.state.employeeNumber || !this.state.businessType) {
                this.props.showProgress(false);
                return toast.error("Select business type, year of establishment, company size");
            } else {
                const {
                    companyName,
                    yearEstablished,
                    employeeNumber,
                    businessType,
                    aboutUs
                } = this.global;
                const firstStep = {
                    companyName,
                    yearEstablished,
                    employeeNumber,
                    businessType,
                    aboutUs
                };
                this.props.showProgress(false);
                this.setState({isSubmitting: false});
                this.setGlobal({companyProfileFirstPage: firstStep,
                    stageOneDone: true, progress: { width: '15%'}});
                this.props.progressHandler({width: '15%'});
                this.props.setActiveStep(2);
            }
        }
    };
        render() {
            return (
                <div className='col-sm-12 col-md-7'>
                    <div className='form-container '>

                  <form onSubmit={this.handleSubmit} className="">
                            <Validator
                                setValidationMessage={(errorMessages) =>
                                    this.setState({errorMessages})
                                }
                                toggleButtonDisable={(value) =>
                                    this.setState({disableButton: value})
                                }
                            >
                                <div className="form-row">

                                <div className="form-group col-12">
                                    <label htmlFor="formGroupExampleInput">Company Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="formGroupExampleInput"
                                        placeholder="Enter company name"
                                        name="companyName"
                                        value={this.state.companyName}
                                        onChange={this.handleChange}
                                        schema={JSON.stringify({
                                            required: true,
                                            label: "Company Name",
                                        })}
                                    />
                                    <span className="submission-warning">
                                    {this.state.errorMessages.emailError}
                                    </span>
                                </div>
                                <div className="form-group col-sm-12 col-md-6">
                                    <label htmlFor="yearOfEstablishment">Year of Establishment</label>
                                    {/* <select
                                        className="form-control"
                                        id="yearOfEstablishment"
                                        name="yearEstablished"
                                        value={this.state.yearEstablished}
                                        onChange={this.handleChange}
                                        schema={JSON.stringify({
                                            required: true,
                                            label: "Year Of establishment",
                                        })}
                                    ><option value=''>Select</option>
                                        {this.yearOptions.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.key}
                                            </option>
                                        ))}
                                    </select> */}
                                    <YearPicker  id="yearOfEstablishment"name="yearEstablished" className="year-picker" onChange={this.handleYear} />
                 
                                    <span className="submission-warning">
                                    {this.state.errorMessages.emailError}
                                </span>
                                </div>
                                <div className="form-group col-sm-12 col-md-6">
                                    <label htmlFor="employeeNumber">Number of Employees</label>
                                    <select
                                        className="form-control"
                                        id="employeeNumber"
                                        name="employeeNumber"
                                        value={this.state.employeeNumber}
                                        onChange={this.handleChange}
                                        schema={JSON.stringify({
                                            required: true,
                                            label: "Number of Employees",
                                        })}
                                    ><option value=''>Select</option>
                                        {this.employeeOptions.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.key}
                                            </option>
                                        ))}
                                    </select>
                                    <span className="submission-warning">
                                    {this.state.errorMessages.emailError}
                                </span>
                                </div>

                                <div className="form-group col-12">
                                    <label htmlFor="businessType">Business Type</label>
                                    <select
                                        className="form-control"
                                        id="businessType"
                                        name="businessType"
                                        value={this.state.businessType}
                                        onChange={this.handleChange}
                                        schema={JSON.stringify({
                                            required: true,
                                            label: "Business Type",
                                        })}
                                    ><option value=''>Select</option>
                                        {this.businessOptions.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.key}
                                            </option>
                                        ))}
                                    </select>
                                    <span className="submission-warning">
                                    {this.state.errorMessages.emailError}
                                </span>
                                </div>

                                <div className="form-group col-12">
                                        <label htmlFor="aboutUs">About Company</label>
                                        <textarea
                                            className="form-control"
                                            id="aboutUs"
                                            placeholder="About Company"
                                            name="aboutUs"
                                            value={this.state.aboutUs}
                                            onChange={this.handleChange}
                                            cols='30'
                                            rows='4'
                                            schema={JSON.stringify({
                                                required: true,
                                                label: "About company",
                                            })}
                                        />
                                        <span className="submission-warning">
                                    {this.state.errorMessages.emailError}
                                    </span>
                                    </div>

                                    <button
                                        className='btn btn-block btn-primary mb-5'
                                        type='submit'
                                        disabled={this.state.isSubmitting}
                                    >
                                        {this.props.store._id ? 'Update and Continue' : 'Save and Continue'}
                                    </button>
                                <span className="submission-warning mb-2">
              {this.state.serverMessage}
            </span>
              </div>
                            </Validator>
                        </form>

                    </div>
                </div>
            );
        }


}
export default CompanyProfileFirstStep;
