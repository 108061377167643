import React, { Fragment, useState } from 'react';
import BarLoader from 'react-spinners/BarLoader';
import CompanyProfileFirstStep from './CompanyProfileFirstStep';
import CompanyProfileSecondStep from './CompanyProfileSecondStep';
import SuccessPage from '../../../../../common/alerts/SuccessPage';
import CompanyProfileThirdStep from './CompanyProfileThirdStep';
import { useParams } from 'react-router-dom';
const CompanyProfileForm = (props) => {
  const initialState = {
    activeStep: 1,
    steps: [
      {
        title: "Company's Profile",
        subTitle: 'Tell us a little about your Company',
      },
      {
        title: "Company's Profile",
        subTitle: 'Tell us how people can contact you',
      },
      {
        title: "Company's Address",
        subTitle: 'Tell us how people can contact you',
      },
      {
        title: "Company's Profile",
        subTitle: '',
      },
    ],
  };
  // get and set global state

  const [state, setState] = useState(initialState);
  const [showProgress, setShowProgress] = useState(false);
  const { userId } = useParams();
  const [store] = useState(
    props.store || {
      _id: null,
      companyName: '',
      yearEstablished: '',
      employeeNumber: '',
      businessType: '',
      aboutUs: '',
      email: '',
      phoneNo: '',
      website: '',
      state: '',
      city: '',
      country: '',
      address: '',
      storeOwner: '',
    }
  );
  const { activeStep, steps } = state;
  const setActiveStep = (step) => {
    setState({
      ...state,
      activeStep: step,
    });
  };

  const toggleProgress = (value) => {
    setShowProgress(value);
    props.progressHandler('getProgress');
  };
  if (activeStep > 3) {
    return (
      <SuccessPage
        title="Company's Profile"
        text='You have successfully created a Store. Now you can proceed to add products to your store!.'
        link='/dashboard/user/list-users/'
        linkLabel='Go to Store'
      />
    );
  }
  let view;
  switch (state.activeStep) {
    case 1:
      view = (
        <CompanyProfileFirstStep
          setActiveStep={setActiveStep}
          store={store}
          showProgress={toggleProgress}
          {...props}
        />
      );
      break;
    case 2:
      view = (
        <CompanyProfileSecondStep
          setActiveStep={setActiveStep}
          store={store}
          showProgress={toggleProgress}
          {...props}
        />
      );
      break;
    case 3:
      view = (
        <CompanyProfileThirdStep
          setActiveStep={setActiveStep}
          showProgress={toggleProgress}
          {...props}
          store={store}
          user={userId}
        />
      );
      break;
    default:
      break;
  }

  return (
    <Fragment>
      <div className='container company-profile'>
        <div className='card ' id='multi-form-card'>
          <div className='card-header'>
            <div className='row'>
              <div className='col-sm-8'>
                <h4 className='text-dark'>{steps[activeStep - 1].title}</h4>
              </div>
              <div className='col-sm-4'>
                <div className='indicators float-right'>
                  {steps.map((step, index) => (
                    <Fragment key={index + '________fragment_step'}>
                      {index === 3 && store._id ? null : (
                        <div
                          disabled={true}
                          className={`rounded-indicator ${
                            activeStep === index + 1
                              ? 'active-step animated fadeIn'
                              : 'inactive-step'
                          }`}
                          onClick={() => {
                            if (activeStep > index + 1) {
                              setState({ ...state, activeStep: index + 1 });
                            }
                          }}
                        >
                          <span>{index + 1}</span>
                        </div>
                      )}
                    </Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <BarLoader
            color={'green'}
            size={150}
            width='100%'
            loading={showProgress}
          />
          <div className='form-content-container px-4 pt-4'>
            <div className='row'>
              <div className='col-sm-5 d-none d-sm-none d-md-block'>
                <p>{steps[activeStep - 1].subTitle}</p>
              </div>

              {view}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CompanyProfileForm;
