import React , {useState,useEffect} from 'react';
import UserService from '../../services/user/UserService';
// import { Link } from 'react-router-dom';
import withDashboard from './hoc/withDashboard';
import {toast} from 'react-toastify';
import Loader from "react-loader-spinner";
import OrderService from '../../services/order/OrderService';
import ProductService from '../../services/product/ProductService';
import StoreService from '../../services/store/StoreService';

export const AdminDashboard = () => {
 
  const [users, setUsers] = useState("");
  const [loading, setLoading] = useState(false);

  // get list of users
  const fetchUsersCount = async () =>{
    setLoading(true);

  const userService = new UserService();
  const response = await userService.getUserCount();

    try {
    if (response && response.status === 'SUCCESS'){
        //  toast.success("User listed successfully!");
        setUsers(response.data);
        setLoading(false);
    } else {
      toast.error(response.msg || 'Unable to list users!');
      setLoading(false);
    }
  } catch (error) {
    toast.error(error.message || 'Unable to register at the moment!');
  }
};


const [products, setProducts] = useState("");

const fetchProductCount = async () =>{

  const productService = new ProductService();
  const response = await productService.getProductCount();
try{
  if (response && response.status === 'SUCCESS'){

    setProducts(response.data);
  } else {
  }

  }catch (error) {
      setLoading(false);
  }
}

const [rfq, setRfq] = useState("");
const fetchRfqCount = async () =>{

  const orderService = new OrderService();
  const response = await orderService.getRfqCount();
try{
  if (response && response.status === 'SUCCESS'){
    console.log("rfq: " + response.data);
      setRfq(response.data);
  } else {
      // setLoading(false);
  }

  }catch (error) {
      setLoading(false);
  }
}

const [Inquiries, setInquiries] = useState("");
const fetchInquiryCount = async () =>{

  const orderService = new OrderService();
  const response = await orderService.getInquiryCount();
try{
  if (response && response.status === 'SUCCESS'){
    console.log("inquiry: " + response.data);
      setInquiries(response.data);
      // setLoading(false);
  } else {
      // setLoading(false);
  }

  }catch (error) {
      setLoading(false);
  }
}

const [activeStores, setActiveStores] = useState("");
const fetchActiveStoresCount = async () =>{

  const storeService = new StoreService();
  const response = await storeService.getStoreCountBasedOnStatus("ACTIVE");
try{
  if (response && response.status === 'SUCCESS'){
      setActiveStores(response.data);
  } else {
      // setLoading(false);
  }

  }catch (error) {
      setLoading(false);
  }
}

const [closedStores, setClosedStores] = useState("");
const fetchClosedStoresCount = async () =>{

  const storeService = new StoreService();
  const response = await storeService.getStoreCountBasedOnStatus("CLOSED");
try{
  if (response && response.status === 'SUCCESS'){
      setClosedStores(response.data);
      // setLoading(false);
  } else {
      // setLoading(false);
  }

  }catch (error) {
      setLoading(false);
  }
}

const [Invoices, setInvoices] = useState("");
const fetchInvoiceCount = async () =>{

  const orderService = new OrderService();
  const response = await orderService.getInvoiceCount();
try{
  if (response && response.status === 'SUCCESS'){
      setInvoices(response.data);
      // setLoading(false);
  } else {
      // setLoading(false);
  }

  }catch (error) {
      setLoading(false);
  }
}

useEffect(() => {
  fetchUsersCount().then();
  fetchProductCount().then();
  fetchRfqCount().then();
  fetchInquiryCount().then();
  fetchInvoiceCount().then();
  fetchActiveStoresCount().then();
  fetchClosedStoresCount().then();
}, []);


  return (
    <div className="dashboard">
     
     {!loading? (
       
<div className="card p-5">

<h3 className="h3 text-dark mb-5">Min.ng admin</h3>
<div className="overview">

  <div className="row">
      <div className="col-md-2 mr-5 mt-3 m-single">
        <div className="d-flex">
             <div className="report">
                 <p>All Users</p>
                 <p><b>{users}</b></p>
             </div>
        </div>
      </div>
   

      <div className="col-md-2 mr-5 mt-3 m-single">
        <div className="d-flex">
             <div className="report">
                 <p>All Products</p>
                 <p><b>{products}</b></p>
             </div>
        </div>
      </div>
          
      <div className="col-md-2 mr-5 mt-3 m-single">
        <div className="d-flex">
             <div className="report">
                 <p>All Rfq</p>
                 <p><b>{rfq}</b></p>
             </div>
        </div>
      </div>

      <div className="col-md-2 mr-5 mt-3 m-single">
        <div className="d-flex">
             <div className="report">
                 <p>All Inquiry</p>
                 <p><b>{Inquiries}</b></p>
             </div>
        </div>
      </div>

      
      <div className="col-md-2 mr-5 mt-3 m-single">
        <div className="d-flex">
             <div className="report">
                 <p>Invoices</p>
                 <p><b>{Invoices}</b></p>
             </div>
        </div>
      </div>

      <div className="col-md-2 mr-5 mt-3 m-single">
        <div className="d-flex">
             <div className="report">
                 <p>Active Stores</p>
                 <p><b>{activeStores}</b></p>
             </div>
        </div>
      </div>

      <div className="col-md-2 mr-5 mt-3 m-single">
        <div className="d-flex">
             <div className="report">
                 <p>Closed stores</p>
                 <p><b>{closedStores}</b></p>
             </div>
        </div>
      </div>
     
  </div>

</div>
</div>
     ):(
              <Loader
              type="BallTriangle"
              height={100}
              width={100}
              timeout={3000} //3 secs
              className="loader"
              /> 
     )}
      
    </div>
  );
};

export default withDashboard(AdminDashboard);