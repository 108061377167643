import React, { Fragment, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import UserService from '../../../../services/user/UserService';
import RandomService from '../../../../utils/randomServices';

import { Link } from 'react-router-dom';
// import withDashboard from '../../hoc/withDashboard';
import {toast} from 'react-toastify';
import {useHistory } from "react-router-dom";


const ListUsers = () => {
  const [loading, setLoading] = useState(false);

  const [usersList, setUsersList] = useState([]);

  const history = useHistory();
  const fetchUsers = async () =>{
      setLoading(true);

    const userService = new UserService();
    const response = await userService.getAllUsers();

      try {
      if (response && response.status === 'SUCCESS'){
           toast.success("User listed successfully!");
          setUsersList(response.data.data);
          setLoading(false);
          console.log('response', JSON.stringify(response.data.data[0].createdAt))
          RandomService.initDatatable('list-users');
      } else {
        toast.error(response.msg || 'Unable to list users!');
        setLoading(false);
        RandomService.initDatatable('list-users', true);
      }
    } catch (error) {
      toast.error(error.message || 'Unable to register at the moment!');
    }
  };

  const viewUser = (userId)=> {
    history.push('/dashboard/user/view-user/' + userId);

}

  const viewStore = (userId)=> {
    history.push('/dashboard/user/view-user-store/' + userId);

  }

  const createStore = (userId)=> {
    history.push('/dashboard/store/create-store/' + userId);

  }

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <Fragment>
      <div className='card px-3 py-5 px-4 list-user'>
        <div>
          <div className='row'>
            <div className='col-sm-12 col-md-4 col-xl-4'>
              <h4>List of Users</h4>
            </div>
            <div className='col-sm-12 col-md-4 col-xl-4 mt-5 mt-md-0 mb-md-0 mb-4'>
              {/*  <label htmlFor="filterProduct">Filter Products</label>
                               <select className="form-control" id="filterProduct" onChange={this.filterProducts}>
                                   <option>ALL</option>
                                   <option>ONLINE</option>
                                   <option>OFFLINE</option>
                                   <option>PENDING</option>
                                   <option>REJECTED</option>
                               </select>*/}
            </div>
            <div className=' col-sm-12 col-md-4 col-xl-4 mt-md-4'>
              <Link to={'/dashboard/user/create-user/'}>
                <button className='m-btn m-btn-primary float-right m-btn-small-round bg-primary'>
                  Create New User
                </button>
              </Link>
            </div>
          </div>

          <div>
            <div className='body mt-5'>
              {loading ? (
                <div className='text-center mt-1 mb-3'>
                  <span className='fa fa-spin fa-spinner fa-2x'></span>&nbsp;
                  <br />
                  <span> Loading, Please wait...</span>
                </div>
              ) : null}
              {!loading ? (
                <div className='table-responsive'>
                  <table
                    id='list-users'
                    className='table table-bordered table-hover table-striped dataTable'
                    cellSpacing='0'
                    width='100%'
                  >
                    <thead>
                      <tr>
                        <th className='no-sort'></th>
                        <th>S/N</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Role</th>
                        <th>Email</th>
                        <th>Phone No</th>
                        <th>Country</th>
                        <th>Created</th>
                        <th>Updated</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tfoot>
                      <tr>
                        <th className='no-sort'></th>
                        <th>S/N</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Role</th>
                        <th>Email</th>
                        <th>Phone No</th>
                        <th>Country</th>
                        <th>Created</th>
                        <th>Updated</th>
                        <th>Action</th>
                      </tr>
                    </tfoot>
                    <tbody>
                      {usersList.map((user, index) => (
                        <tr key={user.id + '_user_' + index} id={user.id}>
                          <td
                            className='details-control'
                            id={'td-' + index}
                          ></td>
                          <td>{index + 1}</td>
                          <td>{user.firstName}</td>
                          <td>{user.lastName}</td>
                          <td>{user.role}</td>
                          <td>{user.email}</td>
                          <td>{user.phoneNumber}</td>
                          <td>{user.country}</td>
                          <td>{user.createdAt}</td>
                          <td>{user.updatedAt}</td>
                          <td>
                          
                                <div className="btn-group">
                                  <button type="button" className="btn btn-success">Action</button>
                                  <button type="button" className="btn btn-success dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span className="sr-only">Toggle Dropdown</span>
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <a className="dropdown-item" href="#!" onClick={() => viewUser(user.id)}>View Profile</a>
                                    {user.role !== 'STORE_ADMIN'? (
                                        <a className="dropdown-item" href="#!" onClick={() => createStore(user.id)}>Create Store</a>
                                    ):(
                                      <a className="dropdown-item" href="#!" onClick={() => viewStore(user.id)}>View Store</a>
                                    )
                                    
                                  }
{/*                                     
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Disable</a> */}
                                  </div>
                                </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ListUsers;
