import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import withDashboard from '../../hoc/withDashboard';
// import {toast} from 'react-toastify';
// import Loader from "react-loader-spinner";
import NumberFormat from 'react-number-format';
// import {useHistory} from 'react-router-dom';
import { useHistory, useParams } from 'react-router-dom';
import ProductService from '../../../../services/product/ProductService';
import { toast } from 'react-toastify';

import { Form, Formik } from 'formik';
import FormikControl from '../../../common/formik/FormikControl';
import { object, string } from 'yup';

export const ProductDetails = () => {
  const { productId } = useParams();

  const [activeImage, setActiveImage] = useState(null);

  const [product, setProduct] = useState([]);

  const fetchProductDetails = async () => {
    const productService = new ProductService();
    const response = await productService.getProductDetails(productId);
    try {
      if (response && response.status === 'SUCCESS') {
        setProduct(response.data);
        setActiveImage(response.data.coverImage);
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      // setLoading(false);
      toast.error(error);
    }
  };


  const initialValues = {
    status_reason: '',
  };

  const validationSchema = object({
    status_reason: string().required(),
  });

  useEffect(
    () => {
      fetchProductDetails().then();
    },
    // eslint-disable-next-line
    []
  );

  const [isRejected, setIsRejected] = useState(false);

  const history = useHistory();

  const productApprovalStatus = async (productId, status) => {
    const productService = new ProductService();

    if(status === "APPROVED"){
            try {
              const response = await productService.productApprovalStatus(productId, {
                approval_status: status,
              });
        
              if (response && response.status === 'SUCCESS') {
                toast.success('Product Status Updated!');
                history.goBack();
              } else {
                toast.error(response.msg);
                // setLoading(false);
              }
            } catch (error) {
              //   setLoading(false);
              console.log('ERRRORR', error);
            }
          };

          
          if (status === "REJECTED"){
              setIsRejected(true);
          }
    }


    const handleSubmit = async (data) =>{
      const productService = new ProductService();
      try {
        const response = await productService.productApprovalStatus(productId, {
          approval_status: "REJECTED",
          status_reason:data.status_reason,
        });
  
        if (response && response.status === 'SUCCESS') {
          toast.success('Product has been rejected successfully!');
          history.goBack();
        } else {
          toast.error(response.msg);
          // setLoading(false);
        }
      } catch (error) {
        //   setLoading(false);
        console.log('ERRRORR', error);
      }
     }
    


   

  return (
    <div className='dashboard'>
      {/* {!loading? ( */}

      <div className='card'>
        <h3 className='h3 text-dark mb-5 p-5'>Product Details</h3>
        <div className='overview'>
          {!!product ? (
            <div className='product-details'>
              {/* image */}

              <div className='row'>
                <div className='col-md-6'>
                  <div className='left mb-5 pt-4'>
                    <img
                      className='img active-img mx-auto w-100 mb-3 mb-xl-n5 m-btn-small-round'
                      src={activeImage}
                      alt='product.productName'
                    />
                  </div>
                </div>
                {/* details */}
                <div className='col-md-6 '>
                  <div className='clearfix'>
                    <p className='float-left'>{product.productName}</p>
                    <NumberFormat
                      className='float-right'
                      value={product.unit_price}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'₦'}
                    />
                  </div>
                  <hr />

                  <p className='text-secondary'>
                    Product category: <b>{product?.categoryId?.categoryName}</b>
                  </p>
                  <p className='text-secondary'>
                    Unit available: <b>{product.units}</b>
                  </p>
                  <p className='text-secondary'>
                    Order Type: <b></b>
                  </p>
                  <p className='text-secondary'>
                    Shipping Class: <b>{product?.shippingClass?.description}</b>
                  </p>
                  <p className='text-secondary'>
                    Shipping Method:{' '}
                    <b>
                      {product?.freeShipping
                        ? 'Free Shipping'
                        : 'Paid Shipping'}
                    </b>
                  </p>
                  <p className='text-secondary'>
                    Minimum Order: <b>{product?.minOrder}</b>
                  </p>
                </div>
              </div>

              {/* description */}

              <div className='mt-5 '>
                <h4 className='text-secondary mb-3'>Product Description</h4>
                <p
                  className=''
                  dangerouslySetInnerHTML={{
                    __html: product.productDescription,
                  }}
                ></p>
              </div>

              {/*  */}
            
            {isRejected === true ? (

                <div>
                        <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                      >
                      {({ setFieldValue, isSubmitting }) => (
                        <Form>
                            <div className='col-md-5'>
                              <FormikControl
                                control='textarea'
                                name='status_reason'
                                label='Why are you rejecting this product?'
                                id='m-input'
                              />
                            </div>

                            <button
                                type='submit'
                                className='btn btn-primary  mt-3  rounded'
                                disabled={isSubmitting}
                            
                            >Submit </button>
                          </Form>
                      )}
                      </Formik>
                </div>


            ):(null)}

              {/* button */}

              <div className='mt-5'>
                <div className='d-flex float-right '>
                  <button
                    className='btn btn-outline-danger mr-3 float-right '
                    onClick={() =>
                      productApprovalStatus(product._id, 'REJECTED')
                    }
                  >
                    Reject product
                  </button>
                  {product.approval_status !== 'APPROVED' ? (
                    <button
                      className='btn btn-outline-success float-right'
                      onClick={() =>
                        productApprovalStatus(product._id, 'APPROVED')
                      }
                    >
                      Approve product
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default withDashboard(ProductDetails);
