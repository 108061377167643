import axios from "axios";

// Note all apiService call accepts url and optional data

export default class FormService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_INVENTORY_BACK;
  }

  createForm = async (data) => {
    try {
      const response = await axios.post(`${this.baseUrl}/form`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  };
  getForm = async (id) => {
    try {
      const response = await axios.get(`${this.baseUrl}/form/${id}`);
      return response.data;
    } catch (error) {
      return error;
    }
  };
  deleteForm = async (id) => {
    try {
      const response = await axios.delete(`${this.baseUrl}/form/${id}`);
      return response.data;
    } catch (error) {
      return error;
    }
  };

  updateForm = async (id, data) => {
    try {
      const response = await axios.patch(`${this.baseUrl}/form/${id}`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  };
}
