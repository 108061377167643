import React from 'react';
import { Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import TextError from './TextError';

const Select = (props) => {
  const { name, label, ...rest } = props;
  return (
    <div className='form-group'>
      <label htmlFor={name}>{label}</label>
      <Field as='select' name={name} className='form-control' {...rest} />

      <ErrorMessage component={TextError} name={name} />
    </div>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default Select;
