import React, { Fragment, setGlobal } from 'reactn';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import HomeRoutes from './configs/HomeRoutes.config';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './theme';
// import ProtectedRoutes from './configs/ProtectedRoutes';
import './assets/css/styles.css';
import 'croppie/croppie.css';
import UserService from './services/user/UserService';
import CategoryService from './services/category/CategoryService';
import FormService from './services/form/FormService';
import CreateUser from './components/dashboard/admin/ManageUser/CreateUser';
import ListUsers from './components/dashboard/admin/ManageUser/ListUsers';
import Login from './components/dashboard/login/Login';
import Dashboard from './components/dashboard/AdminDashboard';
import ListStores from './components/dashboard/admin/ManageStores/ListStores';
import ViewStore from './components/dashboard/admin/ManageStores/ViewUserStores';
import CreateStore from './components/dashboard/admin/ManageStores/CreateStore/SetupStore';
import { ToastContainer } from 'react-toastify';
import ViewUser from './components/dashboard/admin/ManageUser/ViewUser';
import ViewUserStores from './components/dashboard/admin/ManageStores/ViewUserStores';
import StoreDetail from './components/dashboard/admin/ManageStores/StoreDetail';
import ListProducts from './components/dashboard/admin/ManageProduct/ListProducts';
import CreateProduct from './components/dashboard/admin/ManageProduct/create_product/createProducts';
import Settings from './components/dashboard/admin/Settings/MySettings';
import UserOverview  from './components/dashboard/admin/ManageUser/UserOverview';
import StoreOverview from './components/dashboard/admin/ManageStores/StoreOverview';
import ProductOverview  from './components/dashboard/admin/ManageProduct/ProductOverview';
import ProductDetails  from './components/dashboard/admin/ManageProduct/ProductDetails';
import InvoiceOverview from './components/dashboard/admin/ManageInvoices/InvoiceOverview';
import RfqOverview  from './components/dashboard/admin/ManageRfq/RfqOverview';
function App() {
  setGlobal({ UserService, CategoryService, FormService });

  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route exact path='/' component={Login} />
            <Route exact path='/dashboard/user-overview' component={UserOverview} />
            <Route exact path='/dashboard/user/create-user' component={CreateUser} />
            <Route exact path='/dashboard/user/view-user/:userId' component={ViewUser} />
            <Route exact path='/dashboard/user/list-users' component={ListUsers} />
            <Route exact path='/dashboard/user/view-user-store/:userId' component={ViewUserStores} />
            <Route exact path='/dashboard/store-overview' component={StoreOverview} />
            <Route exact path='/dashboard/store/view-store' component={ViewStore} />
            <Route exact path='/dashboard/store/store-detail/:storeId' component={StoreDetail} />
            <Route exact path='/dashboard/store/list-stores' component={ListStores} />
            <Route exact path='/dashboard/product-overview' component={ProductOverview} />
            <Route exact path='/dashboard/products/list-products/:storeId' component={ListProducts} />
            <Route exact path='/dashboard/products/product-details/:productId' component={ProductDetails} />
            <Route exact path='/dashboard/products/create-product/:storeId' component={CreateProduct} />
            <Route exact path='/dashboard/store/create-store/:userId' component={CreateStore} />
            <Route exact path='/dashboard/invoice-overview' component={InvoiceOverview} />
            <Route exact path='/dashboard/rfq-overview' component={RfqOverview} />
            <Route exact path='/dashboard/settings' component={Settings} />
            <Route exact path='/dashboard/' component={Dashboard} />
          </Switch>
        </Router>

        <ToastContainer
          position='top-right'
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
        />
      </ThemeProvider>
    </Fragment>
  );
}

export default App;
