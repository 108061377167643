import React, { useState, Fragment } from 'reactn';
import ProgressBar from '../../../../common/ProgressBar';
import ProductDetails from './ProductInfoForm';
import withDashboard from '../../../hoc/withDashboard';
import {useParams} from "react-router-dom";
const SetupStore = (props) => {
  const [progress] = useState({
    width: '1%',
  });
  const {storeId} =  useParams();
  // const [currentUser, setCurrentUser] = useState(null);
  // const [storeId] = useState(props.match.params.storeId);
  const _product = {
    // _id: null,
    productName: '',
    // modelName: "",
    // brandName: "",
    productDescription: '',
    // productSpecification: "",
    unit_price: 0,
    minOrder: 1,
    units: 0,
    logistics: [],
    categoryId: '',
    categoryName: '',
    storeId: storeId,
    storeOwner: '',
    orderTypeId: '',
    coverImage: '',
    product_sample_price: 0,
    product_sample_mustApprove: true,
    place_on_sale: true,
    customizable: false,
    // VAT: "",
    currency: 'NGN',
    negotiable: false,
    has_product_sample: false,
    // audience: "",
    // shippedBySeller: false,
    weight: 0,
    breadth: 0,
    height: 0,
    length: 0,
    dimension_unit: '',
    weight_unit: '',
    // place_of_origin: "",
    category_form_fields: {},
    form_id: '',
    additional_form_fields: [],
    // keywords: [],
    paymentTerms: '',
    shippingClass: '',
    shippingMethod: '',
    returnPolicy: '7 days',
    // percentageCompletion: 0,
    // states: []
  };
  const [product] = useState(_product);

  return (
    <Fragment>
      <div className='container'>
        <div className='row mb-4'>
          <div className='col-sm-6 col-md-6'>
            <div className='main-header'>
              <h2>Add product to store</h2>
              <h6
                className='text-danger mt-3'
                style={{ fontSize: '11px', marginTop: '-5px' }}
              >
                Product <em>placed on sales</em> requires MIN.NG Approval
              </h6>
            </div>
          </div>
          <div className='col-sm-6 col-md-6'>
            <div className='sub-header-4'>
              <h4>{progress.width} completed</h4>
            </div>
            <ProgressBar progress={progress} />
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-12'>
            <ProductDetails product={product} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withDashboard(SetupStore);
