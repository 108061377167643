import axios from "axios";
import setAuthTokenHeader from "../../utils/setAuthTokenHeader";
export default class ProductService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_INVENTORY_SERVICE_API;
    this.setAuthTokenHeader = setAuthTokenHeader;
    // this.token = localStorage.getItem("token");
      this.token = true; //localStorage.getItem("token");
      this.config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
  }


  async getProductCount () {
    if (this.token) {
      this.setAuthTokenHeader();
    }
    try {

      const response = await axios.get(`${this.baseUrl}/product/count`);

      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internet error', status: 'FAILURE' };
      }
    }
  }

  async productApprovalStatus (productId, status) {
    if (this.token) {
      this.setAuthTokenHeader();
    }
    try {

      const response = await axios.put(`${this.baseUrl}/product/set-approval-status/${productId}`,status);

      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internet error', status: 'FAILURE' };
      }
    }
  }

  async getProductCountBasedOnStatus (status) {
    if (this.token) {
      this.setAuthTokenHeader();
    }
    try {

      const response = await axios.get(`${this.baseUrl}/product/count?approval_status=${status}`);

      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internet error', status: 'FAILURE' };
      }
    }
  }

  async getMostDemandedProducts () {
    if (this.token) {
      this.setAuthTokenHeader();
    }
    try {

      const response = await axios.get(`${this.baseUrl}/product/top-ten-on-demand`);

      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internet error', status: 'FAILURE' };
      }
    }
  }

  async getProductDetails(Id) {
    if (this.token) {
      this.setAuthTokenHeader();
    }
    try {
      console.log(Id);
      const response = await axios.get(`${this.baseUrl}/product/${Id}`);

      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internet error', status: 'FAILURE' };
      }
    }
  }
  async addProduct(data) {
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.post(
        `${this.baseUrl}/product`,
        data,
        this.config
      );
      return response.data;
    } catch (error) {
      if(error && error.response && error.response.data) {
            return error.response && error.response.data;
        } else if(error && error.response) {
            return error.response && error.response.data;
        } else {
            return {msg: "Network or internet error", status: "FAILURE"};

        }
    }
  }
  async postMultimedia(data) {
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.post(`${this.baseUrl}/multimedia`, data);
      return response.data;
    } catch (error) {
      if(error && error.response && error.response.data) {
            return error.response && error.response.data;
        } else if(error && error.response) {
            return error.response && error.response.data;
        } else {
            return {msg: "Network or internet error", status: "FAILURE"};

        }
    }
  }
  async deleteMultimedia(id) {
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.delete(`${this.baseUrl}/multimedia/${id}`);
      return response.data;
    } catch (error) {
      if(error && error.response && error.response.data) {
            return error.response && error.response.data;
        } else if(error && error.response) {
            return error.response && error.response.data;
        } else {
            return {msg: "Network or internet error", status: "FAILURE"};

        }
    }
  }
  async getAllMultimedia(productId) {
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.get(`${this.baseUrl}/multimedia/list-by-entity/${productId}`);
      return response.data;
    } catch (error) {
      if(error && error.response && error.response.data) {
            return error.response && error.response.data;
        } else if(error && error.response) {
            return error.response && error.response.data;
        } else {
            return {msg: "Network or internet error", status: "FAILURE"};

        }
    }
  }
  async updateProduct(productId, data) {
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.put(
        `${this.baseUrl}/product/${productId}`,
        data,
        this.config
      );
      return response.data;
    } catch (error) {
      if(error && error.response && error.response.data) {
            return error.response && error.response.data;
        } else if(error && error.response) {
            return error.response && error.response.data;
        } else {
            return {msg: "Network or internet error", status: "FAILURE"};

        }
    }
  }
  async getProductsByStore(storeId, limit=9000, status=null) {
    console.log("store id", storeId);
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.get(
        status ? `${this.baseUrl}/product/list/store/${storeId}?limit=${limit}&status=${status}` : `${this.baseUrl}/product/list/store/${storeId}?limit=${limit}`
      );
      return response.data;
    } catch (error) {
      if(error && error.response && error.response.data) {
            return error.response && error.response.data;
        } else if(error && error.response) {
            return error.response && error.response.data;
        } else {
            return {msg: "Network or internet error", status: "FAILURE"};

        }
    }
  }
  async getProductsByStoreForInvoice(storeId, limit=9000, status=null) {
    console.log("store id", storeId);
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.get(
        status ? `${this.baseUrl}/product/list/store/${storeId}/for-invoice?limit=${limit}&status=${status}` : `${this.baseUrl}/product/list/store/${storeId}?limit=${limit}`
      );
      return response.data;
    } catch (error) {
      if(error && error.response && error.response.data) {
            return error.response && error.response.data;
        } else if(error && error.response) {
            return error.response && error.response.data;
        } else {
            return {msg: "Network or internet error", status: "FAILURE"};

        }
    }
  }
  async getProducts(storeId) {
    // console.log("store id", storeId);
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
            // ?limit=${limit}&page=${page}
      const response = await axios.get(
        `${this.baseUrl}/product/list/store/${storeId}`
      );
      return response.data;
    } catch (error) {
      if(error && error.response && error.response.data) {
            return error.response && error.response.data;
        } else if(error && error.response) {
            return error.response && error.response.data;
        } else {
            return {msg: "Network or internet error", status: "FAILURE"};

        }
    }
  }
  async getFilteredProducts(userId, filter) {
    // console.log("store id", storeId);
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
            // ?limit=${limit}&page=${page}
      const response = await axios.get(
        `${this.baseUrl}/product/list/user/${userId}?status=${filter.approval_status}`
      );
      return response.data;
    } catch (error) {
      if(error && error.response && error.response.data) {
            return error.response && error.response.data;
        } else if(error && error.response) {
            return error.response && error.response.data;
        } else {
            return {msg: "Network or internet error", status: "FAILURE"};

        }
    }
  }

  async getProduct(productId) {
    // console.log("store id", storeId);
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.get(
        `${this.baseUrl}/product/${productId}`
      );
      return response.data;
    } catch (error) {
      if(error && error.response && error.response.data) {
            return error.response && error.response.data;
        } else if(error && error.response) {
            return error.response && error.response.data;
        } else {
            return {msg: "Network or internet error", status: "FAILURE"};

        }
    }
  }
  async getSingleProductForUpdate(productId) {
    // console.log("store id", storeId);
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.get(
        `${this.baseUrl}/product/${productId}/for-update`
      );
      return response.data;
    } catch (error) {
      if(error && error.response && error.response.data) {
            return error.response && error.response.data;
        } else if(error && error.response) {
            return error.response && error.response.data;
        } else {
            return {msg: "Network or internet error", status: "FAILURE"};

        }
    }
  }

  async validateMyToken(token) {
    console.log("My Token ", token);
    if (token) {
        this.setAuthTokenHeader(token);
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_USER_SERVICE_API}/auth/my/validate-token`
      );/*.then().catch(function (error) {
          console.log('Error ', error);
      });*/
      return response.data;
    } catch (error) {
      console.log('Error HERR ', error);
      if(error && error.response && error.response.data) {
          return error.response && error.response.data;
      } else if(error && error.response) {
          return error.response && error.response.data;
      } else {
          return {msg: "Network or internet error", status: "FAILURE"};

      }
    }
  }
  async getUserByEmail(payload) {
    if (this.token) {
        this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_USER_SERVICE_API}/user/search-by-email`, payload
      );
      return response.data;
    } catch (error) {
      if(error && error.response && error.response.data) {
          return error.response && error.response.data;
      } else if(error && error.response) {
          return error.response && error.response.data;
      } else {
          return {msg: "Network or internet error", status: "FAILURE"};

      }
    }
  }

  async getOrderTypes() {
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.get(`${this.baseUrl}/order-types`);
      return response.data;
    } catch (error) {
      if(error && error.response && error.response.data) {
            return error.response && error.response.data;
        } else if(error && error.response) {
            return error.response && error.response.data;
        } else {
            return {msg: "Network or internet error", status: "FAILURE"};

        }
    }
  }
  async getWeightUnits() {
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.get(`${this.baseUrl}/weight-units`);
      return response.data;
    } catch (error) {
      if(error && error.response && error.response.data) {
            return error.response && error.response.data;
        } else if(error && error.response) {
            return error.response && error.response.data;
        } else {
            return {msg: "Network or internet error", status: "FAILURE"};

        }
    }
  }
  async getDimensionUnits() {
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.get(`${this.baseUrl}/dimension-units`);
      return response.data;
    } catch (error) {
      if(error && error.response && error.response.data) {
            return error.response && error.response.data;
        } else if(error && error.response) {
            return error.response && error.response.data;
        } else {
            return {msg: "Network or internet error", status: "FAILURE"};

        }
    }
  }
  async getSpecialCategories() {
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.get(`${this.baseUrl}/category/special-categories/list`);
      return response.data;
    } catch (error) {
      if(error && error.response && error.response.data) {
            return error.response && error.response.data;
        } else if(error && error.response) {
            return error.response && error.response.data;
        } else {
            return {msg: "Network or internet error", status: "FAILURE"};

        }
    }
  }

  async getShippingLogistics() {
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.get(`${this.baseUrl}/logistics`);
      return response.data;
    } catch (error) {
      if(error && error.response && error.response.data) {
            return error.response && error.response.data;
        } else if(error && error.response) {
            return error.response && error.response.data;
        } else {
            return {msg: "Network or internet error", status: "FAILURE"};

        }
    }
  }

  async getAttachedLogisticsOfShipping(shippingId) {
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.get(`${this.baseUrl}/shipping-class/attached/logistics/${shippingId}`);
      return response.data;
    } catch (error) {
      if(error && error.response && error.response.data) {
            return error.response && error.response.data;
        } else if(error && error.response) {
            return error.response && error.response.data;
        } else {
            return {msg: "Network or internet error", status: "FAILURE"};

        }
    }
  }
  async getShippingClasses() {
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.get(`${this.baseUrl}/shipping-class`);
      return response.data;
    } catch (error) {
      if(error && error.response && error.response.data) {
            return error.response && error.response.data;
        } else if(error && error.response) {
            return error.response && error.response.data;
        } else {
            return {msg: "Network or internet error", status: "FAILURE"};

        }
    }
  }
}
