import React from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';
import NumberFormat from 'react-number-format';

const Currency = (props) => {
  const { name, label, ...rest } = props;
  return (
    <div className='form-group'>
      <label htmlFor={name}>{label}</label>

      <Field name={name}>
        {({ form, field }) => {
          // const { setFieldValue } = form;
          // const { value } = field;
          return (
            <NumberFormat
              {...field}
              {...rest}
              name={name}
              thousandSeparator={true}
              prefix={'₦'}
              className='form-control'
              {...rest}
            />
          );
        }}
      </Field>

      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

Currency.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default Currency;
