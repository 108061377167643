import axios from 'axios';
import setAuthTokenHeader from '../../utils/setAuthTokenHeader';
export default class StoreService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_INVENTORY_SERVICE_API;
    this.ORDER_URL = process.env.REACT_APP_ORDER_SERVICE_API;
    this.setAuthTokenHeader = setAuthTokenHeader;
    this.token = true; //localStorage.getItem("token");
    this.config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
  }

  async createStore(data) {
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.post(
        `${this.baseUrl}/store/admin/create`,
        data,
        this.config
      );
      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internet error', status: 'FAILURE' };
      }
    }
  }

  async getStoreCount() {
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.get(`${this.baseUrl}/store/count/`);
      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internet error', status: 'FAILURE' };
      }
    }
  }

 

  async getAllStore() {
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.get(`${this.baseUrl}/store`);
      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internet error', status: 'FAILURE' };
      }
    }
  }
  async getStoreCountBasedOnStatus(status) {
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.get(`${this.baseUrl}/store/count?status=${status}`);
      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internet error', status: 'FAILURE' };
      }
    }
  }

  async getStoreOrders(storeId) {
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.get(
        `${this.ORDER_URL}order/store/${storeId}`
      );
      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internal server error', status: 'FAILURE' };
      }
    }
  }

  async getStoreOrderByLimit(storeId) {
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.get(
        `${this.ORDER_URL}order/store/${storeId}?limit=5`
      );
      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internal server error', status: 'FAILURE' };
      }
    }
  }
  async getStoreByEmail(data) {
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.post(
        `${this.baseUrl}/store/search-by-email`,
        data
      );
      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internet error', status: 'FAILURE' };
      }
    }
  }

  async getStoreDashboardCounts(storeId) {
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.get(
        `${this.ORDER_URL}dashboard/store/${storeId}`
      );
      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internal server error', status: 'FAILURE' };
      }
    }
  }
  async getStoreProductCount(storeId) {
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.get(
        `${this.baseUrl}/product/store-product/count/${storeId}`
      );
      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internal server error', status: 'FAILURE' };
      }
    }
  }
  async getStoreRequests(storeId) {
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.get(`${this.ORDER_URL}rfq/store/${storeId}`);
      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internal server error', status: 'FAILURE' };
      }
    }
  }

  async getStoreByID(id) {
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.get(`${this.baseUrl}/store/${id}`);
      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internet error', status: 'FAILURE' };
      }
    }
  }

  async getStoreStaffs(storeId) {
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.get(
        `${this.baseUrl}/staff/store/${storeId}`
      );
      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internet error', status: 'FAILURE' };
      }
    }
  }

  async getStoreNotifications(storeId) {
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.get(
        `${this.baseUrl}/notification/store/${storeId}`
      );
      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internet error', status: 'FAILURE' };
      }
    }
  }

  async getUserStore(userId) {
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.get(
        `${this.baseUrl}/store/store-owner/${userId}`
      );
      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internet error', status: 'FAILURE' };
      }
    }
  }

  async countStores(userId) {
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.get(
        `${this.baseUrl}/store/count-store-owned/${userId}`
      );
      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internet error', status: 'FAILURE' };
      }
    }
  }

  async updateStore(id, data) {
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.put(
        `${this.baseUrl}/store/${id}`,
        data,
        this.config
      );
      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internet error', status: 'FAILURE' };
      }
    }
  }

  async deleteStore(id) {
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }
    try {
      const response = await axios.delete(`${this.baseUrl}/store/${id}`);
      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internet error', status: 'FAILURE' };
      }
    }
  }

  async getCategoryName(categoryId){
    if (this.token) {
      this.setAuthTokenHeader(this.token);
    }

    try {
      const response = await axios.get(`${this.baseUrl}/category/${categoryId}`);
      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internet error', status: 'FAILURE' };
      }
    }
  }
}
