import React from 'react';
import Input from './Input';
import Textarea from './Textarea';
import Select from './Select';
import CheckboxGroup from './CheckboxGroup';
import RadioButtons from './RadioButtons';
import DatePicker from './DatePicker';
import Currency from './Currency';

const FormikControl = (props) => {
  const { control, ...rest } = props;
  switch (control) {
    case 'input':
      return <Input {...rest} />;
    case 'currency':
      return <Currency {...rest} />;
    case 'textarea':
      return <Textarea {...rest} />;
    case 'select':
      return <Select {...rest} />;
    // case 'checkbox':
    //   return <SingleCheckbox {...rest} />;
    case 'checkbox':
      return <CheckboxGroup {...rest} />;
    case 'radio':
      return <RadioButtons {...rest} />;

    case 'date':
      return <DatePicker {...rest} />;
    default:
      return null;
  }
};

export default FormikControl;
