// import * as apiService from '../apiservice';
// import { store } from '../../redux/store';
import setAuthTokenHeader from '../../utils/setAuthTokenHeader';
import authUrl from './authUrl';
import axios from 'axios';
// const { dispatch } = store;

const config = {
    headers: {
        'Content-Type': 'application/json',
    },
};


export default class AuthService {
    constructor() {
        this.baseUrl = process.env.REACT_APP_USER_SERVICE;
        this.setAuthTokenHeader = setAuthTokenHeader;
        this.token = true //localStorage.getItem("token");
        this.config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
    }

    // async loginUser(userData) {
    //     try {
    //         // const response = await apiService.post(authUrl.login, userData);
    //         const response = await axios.post(`${this.baseUrl}auth/login`, userData);
    //         return response;
    //     } catch (error) {
    //         // dispatch.auth.setError();
    //     }
    // }

    loginUser = async(data) => {
        try {
            const response = await axios.post(`${this.baseUrl}/auth/login`, data);
            console.log('Res  ', response);
            return response.data;
        } catch (error) {
            console.log('Error ', );

            if (error && error.response && error.response.data) {
                return error.response && error.response.data;
            } else if (error && error.response) {
                return error.response && error.response.data;
            } else {
                return { msg: "Network or internet error", status: "FAILURE" };

            }

        }
    };
    async logoutUser() {
        try {
            this.setAuthTokenHeader();
            // const response = await post(authUrl.logout, {});
            const response = await axios.post(
                authUrl.logout, {},
                config
            );
            return response.data;
        } catch (error) {
            if (error && error.response && error.response.data) {
                return error.response && error.response.data;
            } else if (error && error.response) {
                return error.response && error.response.data;
            } else {
                return { msg: "Network or internet error", status: "FAILURE" };

            }
        }
    }
};

// export default {
// async loginUser(userData) {
//     try {
//         const response = await apiService.post(authUrl.login, userData);
//         dispatch.auth.setToken(response);
//         return response;
//     } catch (error) {
//         dispatch.auth.setError();
//     }
// },

// async recoverPassword(userData) {
//     try {
//         const response = await apiService.post(authUrl.recoverPassword, userData);
//         dispatch.auth.setRegistedUser(response);
//         return response;
//     } catch (error) {
//         dispatch.auth.setError();
//     }
// },



// };