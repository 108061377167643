import React , {useState,useEffect} from 'react';
// import { Link } from 'react-router-dom';
import withDashboard from '../../hoc/withDashboard';
import StoreService from '../../../../services/store/StoreService';
// import {toast} from 'react-toastify';
import Loader from "react-loader-spinner";
import {useHistory} from 'react-router-dom';
import ListStores from './ListStores';

export const StoreOverview = () => {

    const history = useHistory();
 
  const [loading, setLoading] = useState(false);

  const [stores, setStores] = useState("");

  const fetchStoresCount = async () =>{
  
    const storeService = new StoreService();
    const response = await storeService.getStoreCount();
  try{
    if (response && response.status === 'SUCCESS'){
        setStores(response.data);
        // setLoading(false);
    } else {
        // setLoading(false);
    }
  
    }catch (error) {
        setLoading(false);
    }
  }
  
  const [activeStore, setActiveStore] = useState("");
  const fetchActiveStoresCount = async () =>{
  
    const storeService = new StoreService();
    const response = await storeService.getStoreCountBasedOnStatus("ACTIVE");
  try{
    if (response && response.status === 'SUCCESS'){
        setActiveStore(response.data);
        // setLoading(false);
    } else {
        // setLoading(false);
    }
  
    }catch (error) {
        setLoading(false);
    }
  }
  
  const [inactiveStore, setInActiveStore] = useState("");
  const fetchInActiveStoresCount = async () =>{
  
    const storeService = new StoreService();
    const response = await storeService.getStoreCountBasedOnStatus("INACTIVE");
  try{
    if (response && response.status === 'SUCCESS'){
      console.log("Inactive:" + response.data);
        setInActiveStore(response.data);
        // setLoading(false);
    } else {
        // setLoading(false);
    }
  
    }catch (error) {
        setLoading(false);
    }
  }
  
  const [closedStore, setClosedStore] = useState("");
  const fetchClosedStoresCount = async () =>{
  
    const storeService = new StoreService();
    const response = await storeService.getStoreCountBasedOnStatus("CLOSED");
  try{
    if (response && response.status === 'SUCCESS'){
        setClosedStore(response.data);
        // setLoading(false);
    } else {
        // setLoading(false);
    }
  
    }catch (error) {
        setLoading(false);
    }
  }

  const [blockedStore, setBlockedStore] = useState("");
  const fetchBlockedStoresCount = async () =>{
  
    const storeService = new StoreService();
    const response = await storeService.getStoreCountBasedOnStatus("BLOCKED");
  try{
    if (response && response.status === 'SUCCESS'){
        setBlockedStore(response.data);
        // setLoading(false);
    } else {
        // setLoading(false);
    }
  
    }catch (error) {
        setLoading(false);
    }
  }
  

useEffect(() => {
  fetchStoresCount().then();
  fetchActiveStoresCount().then();
  fetchInActiveStoresCount().then();
  fetchClosedStoresCount().then();
  fetchBlockedStoresCount().then();

}, []);


  return (
    <div className="dashboard">
     
     {!loading? (
       
<div className="card p-5">

<h3 className="h3 text-dark mb-5">Min.ng admin</h3>
<div className="overview">
    <h3 className="text-dark ml-n4 mb-5">Store Overview</h3>
  <div className="row">
      <div className="col-md-2 mr-5 mt-3 m-single click" onClick={() => history.push('/dashboard/store/list-stores/')}>
        <div className="d-flex">
             <div className="report">
                 <p>All Stores</p>
                 <p><b>{stores}</b></p>
             </div>
        </div>
      </div>

      <div className="col-md-2 mr-5 mt-3 m-single">
        <div className="d-flex">
             <div className="report">
                 <p>Active Stores</p>
                 <p><b>{activeStore}</b></p>
             </div>
        </div>
      </div>

      <div className="col-md-2 mr-5 mt-3 m-single">
        <div className="d-flex">
             <div className="report">
                 <p>Inactive Stores</p>
                 <p><b>{inactiveStore}</b></p>
             </div>
        </div>
      </div>

      <div className="col-md-2 mr-5 mt-3 m-single">
        <div className="d-flex">
             <div className="report">
                 <p>Closed Stores</p>
                 <p><b>{closedStore}</b></p>
             </div>
        </div>
      </div>
    
      <div className="col-md-2 mr-5 mt-3 m-single">
        <div className="d-flex">
             <div className="report">
                 <p>Blocked Stores</p>
                 <p><b>{blockedStore}</b></p>
             </div>
        </div>
      </div>

     
          
    
     
  </div>

</div>

<br/><br/><br/>

<ListStores/>
</div>
     ):(
              <Loader
              type="BallTriangle"
              height={100}
              width={100}
              timeout={3000} //3 secs
              className="loader"
              /> 
     )}
      
    </div>
  );
};

export default withDashboard(StoreOverview);