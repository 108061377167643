import React from 'react';
import logo from '../../../assets/img/logo/min.png';
import search from '../../../assets/img/icons/search.png';
import RandomService from './../../../utils/randomServices';
import { useHistory } from 'react-router-dom';


const Header = ({status,onClick}) => {
  const history = useHistory();
  const handleLogout = (e) => {
    e.preventDefault();
    RandomService.eraseCookie(process.env.REACT_APP_USER);
    RandomService.eraseCookie(process.env.REACT_APP_TOKEN);
    history.push('/');
  }



    const openMinPortal = (e) => {
      e.preventDefault();
      window.location.href =
              `${process.env.REACT_APP_HOME_PAGE_FRONTEND}`;
      }



  return (
    <div className='dashboard-header'>

      <div className='top mb-1 d-block d-sm-block d-md-none d-lg-none' >
        <img src={logo} alt='logo' width='100' height='35' onClick={openMinPortal}  />
     
      </div>

      <header className='head'>
        <div
          className='toggle d-block d-sm-block d-md-none d-lg-none'
          id='toggle' onClick={e =>onClick(e.target.stats)}
        >
          <span className='one'></span>
          <span className='two'></span>
          <span className='three'></span>
        </div>
        <div className='logo d-md-block d-none d-lg-block'>
          <img src={logo} alt='logo'  onClick={openMinPortal} />
        </div>

      <div className='search-div mx-auto'>
          <form className='srch-form '>
            <img src={search} width='20' height='20' alt='' />
            <input
              type='text'
              placeholder='Search by product name or category'
            />
          </form>
        </div>

        <div className='dropdown mr-md-4 mt-xl-2 mt-2 ml-md-2 ml-xl-3'>
            <button
              className='btn myacct-btn dropdown-toggle dropleft'
              type='button'
              id='dropdownMenuButton'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <span className='acct'>AA</span>&nbsp;
              <span className='myact'>My Account</span>
            </button>
            <div className='dropdown-menu logout-dropdown' aria-labelledby='dropdownMenuButton'>
           
              <a className='dropdown-item' href='#!' onClick={handleLogout}>
                Logout
              </a>
            </div>
          </div>

         
      </header>
    </div>
  );
};

export default Header;
