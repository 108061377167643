import React from 'react';

import acct from '../../../assets/img/icons/acct icon.png';
import {useHistory,useLocation } from 'react-router-dom';
// Testing

const Sidebar = (props) => {
  const location = useLocation();
  const history = useHistory();

  return (
    <div
      className={props.display + " side-nav"}
      id='side-nav'
    >
      <ul>
      
        <li onClick={() => history.push('/dashboard/')} className={location.pathname === '/dashboard/' ? '_active' : ''}>
         
            <a href='#/'>
              <img src={acct} alt='' /><span>My Account</span>
            </a>
        </li>
        <li onClick={() => history.push('/dashboard/user-overview')}className={location.pathname === '/dashboard/user/list-users/' || location.pathname === '/dashboard/user/create-user/' || location.pathname === '/dashboard/user-overview' ? '_active' : ''}>
          
            <a href='#/'>
              <img src={acct} alt='' />  <span>Users</span>
            </a>
        </li>

        <li onClick={() => history.push('/dashboard/product-overview')}className={location.pathname === '/dashboard/product-overview'? '_active' : ''}>
          
          <a href='#/'>
            <img src={acct} alt='' />  <span>Products</span>
          </a>
      </li>

        
        <li onClick={() => history.push('/dashboard/store-overview')}
          className={
            location.pathname === '/dashboard/store/list-stores/' ||
            location.pathname === '/dashboard/store/view-store'||
            location.pathname === '/dashboard/store-overview'
              ? '_active'
              : ''
          }
        >
            <a href='#/'>
              <img src={acct} alt='' /> <span>Stores</span>
            </a>
        </li>

        <li onClick={() => history.push('/dashboard/invoice-overview')}className={location.pathname === '/dashboard/invoice-overview'? '_active' : ''}>
          
          <a href='#/'>
            <img src={acct} alt='' />  <span>Invoice</span>
          </a>
      </li>

      <li onClick={() => history.push('/dashboard/rfq-overview')}className={location.pathname === '/dashboard/rfq-overview'? '_active' : ''}>
          
          <a href='#/'>
            <img src={acct} alt='' />  <span>Rfq</span>
          </a>
      </li>
      
        <li onClick={() => history.push('/dashboard/settings')}
          className={
            location.pathname === '/dashboard/settings' ||
            location.pathname === '/dashboard/settings'
              ? '_active'
              : ''
          }
        >
            <a href='#/'>
              <img src={acct} alt='' /> <span>Settings</span>
            </a>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
