import React, { Fragment, useState,useEffect } from 'react';

// import {createUseStyles} from 'react-jss';
import Loader from 'react-loader-spinner';
import {useParams} from "react-router-dom";

import { Link } from 'react-router-dom';
import StoreService from '../../../../services/store/StoreService';

// import UserService from '../../../../services/user/UserService';
import withDashboard from '../../hoc/withDashboard';
import {toast} from 'react-toastify';
// import ProductionCapacityService from '../../../../services/productioncapacity/ProductionCapacityService';

const StoreDetails = () => {

  
    const {storeId} =  useParams();
  

  const [loading,setLoading] = useState(false);
  const [store, setStore] = useState([])
  const fetchUser = async () => {
    setLoading(true);

    const storeService = new StoreService();
    try {
      const response = await storeService.getStoreByID(storeId);
      if (response.status === "SUCCESS") {

        setStore(response.data);
        // toast.success('User details successful!');
        setLoading(false);
      }else{
        toast.error(response.msg || 'Unable to View User!');
        setLoading(false);
      }
    } catch (error) {
        toast.error(error.message || 'Unable to view user at the moment!');
    setLoading(false);
    }
  };


//   const fetchProductionCapacity = async () =>{

//     const productionCapacityService = new ProductionCapacityService();

//     try{

//         const response = await productionCapacityService.getProductionCapacityByStoreId(storeId);

//         if( response.status === "SUCCESS"){
//                 console.log("response: " + JSON.stringify(response.data));
//         }

//     }catch (error) {
//         toast.error(error.message || 'Unable to view user at the moment!');
//     setLoading(false);
//     }

//   }

  useEffect(() => {
    fetchUser().then();
    // fetchProductionCapacity().then();
},
// eslint-disable-next-line
[]);
  


  return (
    <Fragment>
      <div className='card p-3 mt-4 pb-5 store-detail'>
        <div className='view-user'>
          <div className='row p-4'>
            <div className='col-6 col-md-9 col-xl-9'>
              <Link to='/dashboard/store-overview'>
                <p className='head'>Go Back</p>
              </Link>
            </div>

          
           
          </div>

          <h4 className='mb-5 ml-4'>Store Details</h4>

          {/* account */}
          
          {!loading ? (
            
          <div className='px-4 mb-5'>
              
            {/* Company Prolie */}
               
               <h3><b>Company Profile</b></h3>
               <div className='row mt-5 px-4'>
                  <div className='col-md-5'>
                      <label htmlFor=""><b>Company Name</b></label>
                      <p>{store.companyName}</p>
                
                  </div>
                  <div className='col-md-5'>
                      <label htmlFor=""><b>Business Type</b></label>
                      <p>{store.businessType}</p>
                
                  </div>
                </div>

                {/* <div className='row px-4'>
                  <div className='col-md-5'>

                    <label htmlFor=""> <b>Product Capacity</b></label>
                    <p>{store.productCapacity}</p>
                  
                  </div>
                  <div className='col-md-5'>
                  <label htmlFor=""> <b>Employee Numbers</b></label>
                    <p>{store.employeeNumber}</p>
                   
                  </div>
                </div> */}

                <div className='row px-4'>
                  <div className='col-md-5'>
                    
                   <label htmlFor="yearOfEstablishment"><b>Year of Establishment</b></label>
                    <p>{store.yearEstablished}</p>            
              
                  </div>
                  <div className='col-md-5'>
                  <label htmlFor=""><b>Phone Number</b></label>
                    <p>{store.phoneNo}</p>
                  
                  </div>
                </div>

                <div className='row px-4'>
                  <div className='col-md-5'>
                  <label htmlFor=""> <b>Country</b></label>
                    <p>{store.country}</p>
                
                  </div>
                  <div className='col-md-5'>
                  <label htmlFor=""> <b>State</b></label>
                    <p>{store.state}</p>
                   
                  </div>
                </div>

                <div className='row px-4 mt-3'>
                  <div className='col-md-5'>
                  <label htmlFor=""> <b>State</b></label>
                    <p>{store.state}</p>
                  
                  </div>
                  <div className='col-md-5'>
                  <label htmlFor=""> <b>Postal Code</b></label>
                    <p>{store.postalCode}</p>
                  
                  </div>
                </div>

                <div className='col-md-10 '>
                <label htmlFor=""> <b>Address</b></label>
                    <p>{store.address}</p>
               
                </div>

                <div className='col-md-10 '>
                <label htmlFor=""> <b>About Us</b></label>
                    <p>{store.aboutUs}</p>
               
                </div>

                <hr/>
               
               

                

               
            </div>

            
            ): (
                <Loader
                type="BallTriangle"
                height={100}
                width={100}
                timeout={3000} //3 secs
                className="loader"
                /> 
            )}
          </div>
      </div>
    </Fragment>
  );
};

// export default ListOrders;

export default withDashboard(StoreDetails);
