import React, { setGlobal, useGlobal } from 'reactn';
import { Formik, Form } from 'formik';
import { string, object } from 'yup';
import FormikControl from '../../../../../common/formik/FormikControl';

const CompanyProfileSecondStep = (props) => {
  const [global] = useGlobal();

  const validationSchema = object({
    email: string().email('Email is required').required('Required'),
    address: string(),
    phoneNo: string(),
  });
  const handleSubmit = async (values, actions) => {
    if (props.store._id) {
      props.showProgress(true);
      const storeService = new global.StoreService();
      const response = await storeService.updateStore(props.store._id, values);
      if (response.status === 'SUCCESS') {
        props.setActiveStep(3);
        actions.setSubmitting(false);
        props.showProgress(false);
      } else {
          actions.setSubmitting(false);
          props.showProgress(false);
      }
    } else {
      setGlobal({ ...values, companyProfileSecondPage: values, progress: { width: '20%'}, stageTwoDone: true });
      props.setActiveStep(3);
      props.progressHandler({width: '20%'});
      actions.setSubmitting(false);
    }
  };
  return (
    <div className='col-sm-12 col-md-7'>
      <div className='form-container'>
        <Formik
          enableReinitialize={true}
          initialValues={
            props.store._id
              ? props.store
              : { ...global.companyProfileSecondPage }
          }
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting }) => (
            <Form>
              <FormikControl
                control='input'
                type='email'
                name='email'
                label='Company Email'
              />
              <FormikControl
                control='input'
                type='text'
                name='phoneNo'
                label='Official Phone Number'
              />
              <FormikControl
                control='input'
                type='text'
                name='website'
                label='Official Website'
              />

              <button
                className='btn btn-block btn-primary mb-5'
                type='submit'
                disabled={isSubmitting}
              >
                {props.store._id ? 'Update and Continue' : 'Save and Continue'}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CompanyProfileSecondStep;
