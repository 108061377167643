import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
import TextError from './TextError';

const RadioButtons = (props) => {
  const { label = null, name } = props;
  return (
    <div className='form-group'>
      {label && <label>{label}</label>}
      {props.children}
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
};

RadioButtons.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default RadioButtons;
