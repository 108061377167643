import axios from 'axios';
import setAuthTokenHeader from '../../utils/setAuthTokenHeader';

// Note all apiService call accepts url and optional data

export default class OrderService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_ORDER_SERVICE_API;
    this.setAuthTokenHeader = setAuthTokenHeader;
    this.token = true; //localStorage.getItem("token");
    this.config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
  }
 

  async getRfqCount () {
    if (this.token) {
      this.setAuthTokenHeader();
    }
    try {
      const response = await axios.get(`${this.baseUrl}/rfq/count`);

      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internet error', status: 'FAILURE' };
      }
    }
  }

 


  async getAllRfq () {
    // console.log("store id", storeId);
    if (this.token) {
      this.setAuthTokenHeader();
    }
    try {
      // ?limit=${limit}&page=${page}

      const response = await axios.get(`${this.baseUrl}/rfq`);

      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internet error', status: 'FAILURE' };
      }
    }
  }

  async getRfqCountBasedOnStatus (status) {
    if (this.token) {
      this.setAuthTokenHeader();
    }
    try {

      const response = await axios.get(`${this.baseUrl}/rfq/count?status=${status}`);

      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internet error', status: 'FAILURE' };
      }
    }
  }


  async getNoMatchCount () {
    if (this.token) {
      this.setAuthTokenHeader();
    }
    try {

      const response = await axios.get(`${this.baseUrl}/rfq/rfq-wih-no-match-store`);

      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internet error', status: 'FAILURE' };
      }
    }
  }


  async getInquiryCount () {
    if (this.token) {
      this.setAuthTokenHeader();
    }
    try {
      const response = await axios.get(`${this.baseUrl}/inquiry/count`);

      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internet error', status: 'FAILURE' };
      }
    }
  }
  async getInvoiceCount () {
    if (this.token) {
      this.setAuthTokenHeader();
    }
    try {

      const response = await axios.get(`${this.baseUrl}/invoice/count`);

      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internet error', status: 'FAILURE' };
      }
    }
  }

  async getInvoiceCountBasedOnStatus (status) {
    if (this.token) {
      this.setAuthTokenHeader();
    }
    try {

      const response = await axios.get(`${this.baseUrl}/invoice/count?status=${status}`);

      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internet error', status: 'FAILURE' };
      }
    }
  }

  async getPaidInvoiceStats () {
    if (this.token) {
      this.setAuthTokenHeader();
    }
    try {

      const response = await axios.get(`${this.baseUrl}/invoice/percent-paid`);

      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internet error', status: 'FAILURE' };
      }
    }
  }


  async getPendingInvoiceStats () {
    if (this.token) {
      this.setAuthTokenHeader();
    }
    try {

      const response = await axios.get(`${this.baseUrl}/invoice/percent-pending`);

      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internet error', status: 'FAILURE' };
      }
    }
  }

  async getDeclinedInvoiceStats () {
    if (this.token) {
      this.setAuthTokenHeader();
    }
    try {

      const response = await axios.get(`${this.baseUrl}/invoice/percent-rejected`);

      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internet error', status: 'FAILURE' };
      }
    }
  }



  async getTotalSales () {
    if (this.token) {
      this.setAuthTokenHeader();
    }
    try {

      const response = await axios.get(`${this.baseUrl}/invoice/total-sales`);

      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data) {
        return error.response && error.response.data;
      } else if (error && error.response) {
        return error.response && error.response.data;
      } else {
        return { msg: 'Network or internet error', status: 'FAILURE' };
      }
    }
  }



  }
