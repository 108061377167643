import React, { Fragment, useState,useEffect } from 'react';

// import {createUseStyles} from 'react-jss';
import Loader from 'react-loader-spinner';
import {useParams} from "react-router-dom";

import { Link } from 'react-router-dom';


import { toast } from 'react-toastify';
import UserService from '../../../../services/user/UserService';
import withDashboard from '../../hoc/withDashboard';

const ViewUser = () => {

  
    const {userId} =  useParams();
  

  const [loading,setLoading] = useState(false);
  const [user, setUser] = useState([])
  const fetchUser = async () => {
    setLoading(true);

    const userService = new UserService();
    try {
      const response = await userService.getUser(userId);
      if (response.status === "SUCCESS") {

        setUser(response.data);
        toast.success('User details successful!');
        setLoading(false);
      }else{
        toast.error(response.msg || 'Unable to View User!');
        setLoading(false);
      }
    } catch (error) {
        toast.error(error.message || 'Unable to view user at the moment!');
    setLoading(false);
    }
  };

  useEffect(() => {
    fetchUser().then();
},
// eslint-disable-next-line
[]);
  


  return (
    <Fragment>
      <div className='card p-3 mt-4 pb-5 create-user'>
        <div className='view-user'>
          <div className='row p-4'>
            <div className='col-6 col-md-9 col-xl-9'>
              <Link to='/dashboard/user/list-users'>
                <p className='head'>Go Back</p>
              </Link>
            </div>

            {user.role !== 'STORE_ADMIN'? (
                <Link to={'/dashboard/store/create-store/'+ user._id}>
                <button className='m-btn m-btn-primary float-right m-btn-small-round bg-primary'>
                  Create Store
                </button>
              </Link>
            ):(
                <Link to={'/dashboard/user/view-user-store/'+ user._id}>
                <button className='m-btn m-btn-primary float-right m-btn-small-round bg-primary'>
                  View Store
                </button>
              </Link>
            )}
           
          </div>

          {/* account */}
          
          
            
          <div className='px-4 mb-5'>
              
            <h4 className='mb-5'>User Profile</h4>

            {!loading ? (
                <div>
                     <div className="row">
                    <div className="col-md-4 user-item">
                        <label><b>First Name</b></label>
                        <p>{user.firstName}</p>
                    </div>
                    <div className="col-md-4 user-item">
                        <label><b>Last Name</b></label>
                        <p>{user.lastName}</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 user-item">
                        <label><b>Email Address</b></label>
                        <p>{user.email}</p>
                    </div>
                    <div className="col-md-4 user-item">
                        <label><b>Phone Number</b></label>
                        <p>{user.phoneNumber}</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 user-item">
                        <label><b>Country</b></label>
                        <p>{user.country}</p>
                    </div>
                    <div className="col-md-4 user-item">
                        <label><b>State</b></label>
                        <p>{user.state}</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 user-item">
                        <label><b>City</b></label>
                        <p>{user.city}</p>
                    </div>
                    <div className="col-md-4 user-item">
                        <label><b>Postal Code</b></label>
                        <p>{user.postalCode}</p>
                    </div>
                </div>

                <div className="row">
                   
                    <div className="col-md-4 user-item">
                        <label><b>Role</b></label>
                        <p>{user.role}</p>
                    </div>
                </div>
               </div>     

            ):(
                <Loader
              type="BallTriangle"
              height={100}
              width={100}
              timeout={3000} //3 secs
              className="loader"
              /> 
            )}
               
           
            </div>
          </div>
      </div>
    </Fragment>
  );
};

// export default ListOrders;

export default withDashboard(ViewUser);
