// eslint-disable;
// eslint-disable-next-line
import React, { Component } from 'react';

import firebase from "../configs/firebase.config";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import "../assets/datatables/datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "../assets/datatables/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";

import { toast } from 'react-toastify';

import ProductService from "../services/product/ProductService";
import Authorization from "../utils/authorization";
import AuthService from "../services/auth/AuthService";
// import * as $ from 'jquery';

import 'datatables.net-dt/css/jquery.dataTables.css'
// import 'datatables.net-bs/css/dataTables.bootstrap.css';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
require('jszip');
require('pdfmake');
require('datatables.net-bs4');
require('datatables.net-buttons-bs4');
require('datatables.net-buttons/js/buttons.colVis.js');
require('datatables.net-buttons/js/buttons.flash.js');
require('datatables.net-buttons/js/buttons.html5.js');
require('datatables.net-buttons/js/buttons.print.js');
require('datatables.net-responsive-bs4');

const $ = require('jquery');
$.DataTable = require('datatables.net');
// require( 'datatables.net-buttons' )( window, $ );
const MySwal = withReactContent(Swal);
let dataTableInstance = null;

export default {
    setToken(data) {
        localStorage.setItem(process.env.REACT_APP_TOKEN, data.token);
    },
    async getAuthUser() {
        const token = this.getToken();
        const authorization = new Authorization();
        const userDetails = await authorization.verifyToken(token);
        console.log("Login User details ", userDetails);
        if (userDetails && userDetails.success) {
            return userDetails;
        } else {
            return null;
        }
    },
    setUser(data) {
        localStorage.setItem(process.env.REACT_APP_USER, JSON.stringify(data));
    },
    getToken() {
        return this.getCookie(process.env.REACT_APP_TOKEN) || null;
    },
    getUser() {
        return this.getCookie(process.env.REACT_APP_USER) || null;
    },
    logout() {
        // todo: log user out on the backend!
        this.logUserOut(() => {
            this.eraseCookie(process.env.REACT_APP_TOKEN);
            this.eraseCookie(process.env.REACT_APP_USER);
            this.removeStorage(process.env.REACT_APP_TOKEN);
            this.removeSession(process.env.REACT_APP_TOKEN);
            setTimeout(() => {
                window.location.href = `${process.env.REACT_APP_USER_SERVICE_FRONTEND}?redirect_to=${process.env.REACT_APP_INVENTORY_SERVICE_FRONTEND}`;
            }, 2000);
        });
    },
    async logUserOut(cb) {
        const authService = new AuthService();
        const response = await authService.logoutUser();
        console.log('RES ', response);
        if (response && response.status === 'SUCCESS') {
            cb();
        } else {
            toast.error('Unable to logout, please retry');
        }
    },
    removeSession() {
        sessionStorage.removeItem(process.env.REACT_APP_TOKEN);
        sessionStorage.removeItem(process.env.REACT_APP_USER)
    },
    removeStorage() {
        localStorage.removeItem(process.env.REACT_APP_TOKEN);
        localStorage.removeItem(process.env.REACT_APP_USER)
    },
    setCookie(name, value, days) {
        let expires = "";
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    getCookie(name) {
        let nameEQ = name + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    },
    eraseCookie(name) {
        document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },
    async runTokenChecker(token) {
        const productService = new ProductService();
        const response = await productService.validateMyToken(token);
        console.log('Response From TOKEN VALIDATOR ', response);
        if (response.status === 'SUCCESS') {

        } else {
            toast.error('Invalid authorization token');
            this.logout();
            window.location.href = `${process.env.REACT_APP_USER_SERVICE_FRONTEND}?redirect_to=${window.location.href}`;
            // todo: user frame to communicate to all Front service about logout action!
        }
    },
    async checkTokenFromServer(token) {
        this.runTokenChecker(token);
        setInterval(async() => {
            this.runTokenChecker(token);
        }, 1000 * 60 * 3);
    },



    deleteImages(file, path = "minng_files") {
        // console.log('FIRL ', file, path);
        const storageRef = firebase.storage().ref();
        const deleteTask = storageRef.child(`${path}/${file}`);
        deleteTask.delete().then(() => {}).catch(() => {});
    },



    initDatatable(id, responsive = true, destroy = 'destroy') {

        if (dataTableInstance && destroy === 'destroy') {
            console.log('DESTROYER ', dataTableInstance);
            dataTableInstance.destroy();
        }

        const buttons = ['pdf', 'print', 'excel', 'csv', 'copy'];
        setTimeout(() => {
            dataTableInstance = $('#' + id).DataTable({
                pagingType: 'full_numbers',
                dom: 'Blfrtip',
                keys: !0,
                buttons: buttons,
                // order: [[1, 'asc']],
                language: {
                    search: '_INPUT_',
                    searchPlaceholder: 'Search...',
                    paginate: {
                        previous: '<i class=\'fa fa-angle-left\'>',
                        next: '<i class=\'fa fa-angle-right\'>'
                    }
                },
                select: {
                    // style: 'multi'
                },
                columnDefs: [{
                        targets: 'no-sort',
                        orderable: false,
                    },
                    { responsivePriority: 1, targets: 0 },
                    { responsivePriority: 2, targets: -1 }
                ],
                'lengthMenu': [
                    [50, 100, 150, -1],
                    [50, 100, 150, 'All']
                ],
                responsive: responsive,
            });
            $('.dt-buttons .btn').removeClass('btn-secondary').addClass('btn-sm btn-primary');






        }, 200);
    },





    handleIconSwitch(target) {
        if (target.hasClass('isShown')) {
            target.removeClass('isShown');
            target.addClass('isNotShown');
        } else {
            target.addClass('isShown');
            target.removeClass('isNotShown');
        }
    },
    confirmAction(callback) {
        MySwal.fire({
            title: 'Are you sure?',
            text: `You won't be able to revert action! `,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonClass: 'btn btn-success ml-1',
            cancelButtonClass: 'btn btn-danger mr-1',
            buttonsStyling: false,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                callback();
            } else if (
                // Read more about handling dismissals
                result.dismiss === MySwal.DismissReason.cancel
            ) {
                console.log('Action not completed!');
            }
        });
    }








}

// export default RandomServices;