/*eslint-disable*/
import React from 'react';
import { Field, ErrorMessage } from 'formik';
import NumberFormat from 'react-number-format';
import TextError from './TextError';
import 'react-datepicker/dist/react-datepicker.css';

function DatePicker(props) {
  const { label, name, ...rest } = props;
  return (
    <div className='form-group'>
      <label htmlFor={name}>{label}</label>
      <Field name={name}>
        {({ form, field }) => {
          const { setFieldValue } = form;
          const { value } = field;
          return (
            <NumberFormat
              {...field}
              {...rest}
              name={name}
              format='##/##/####'
              placeholder='DD/MM/YYYY'
              mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
              className='form-control'
              {...rest}
            />
            // <DateView
            //   id={name}
            //   {...field}
            //   {...rest}
            //   selected={value}
            //   onChange={(val) => setFieldValue(name, val)}
            //   showYearDropdown={true}
            //   dropdownMode='select'
            // />
          );
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default DatePicker;
