import React, { Fragment } from 'react';

import { Form, Formik } from 'formik';
import PasswordIcon from '../../../common/svg/GeneratePasswordIcon';
import FormikControl from '../../../common/formik/FormikControl';
import Loader from 'react-loader-spinner';

import { Link, useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';
import UserService from '../../../../services/user/UserService';
import { object, string } from 'yup';
import withDashboard from '../../hoc/withDashboard';
import { getCountryList } from '../../../../utils/getCountryList';
import * as generator from 'generate-password';

const CreateUser = () => {
  const generatePassword = () => {
    return generator.generateMultiple(1, {
      length: 10,
      numbers: true,
    });
  };

  const history = useHistory();

  const navigateTo = (url) => {
    history.push(url);
  };

  const initialValues = {
    firstName: '',
    lastName: '',
    country: '',
    email: '',
    phoneNumber: '',
    state: '',
    city: 'Portland',
    address: '',
    postalCode: '',
    role: '',
    password: '',
  };

  const validationSchema = object({
    firstName: string().required(),
    lastName: string().required(),
    email: string().email().required(),
    phoneNumber: string().required(),
    address: string().required(),
    state: string().required(),
    postalCode: string().required(),
    city: string().required(),
    role: string().required(),
    country: string().required(),
    password: string()
      .required()
      .min(8, 'Password must be at least 8 Characters!'),
  });

  const handleSubmit = async (data, actions) => {
    actions.setSubmitting(true);
    const userService = new UserService();
    try {
      const response = await userService.registerUser(data);
      if (response.status === 'SUCCESS') {
        // this.setGlobal({ registrationSuccess: true });

        toast.success(response.msg || 'Registration successful!');
        actions.setSubmitting(false);
        navigateTo('/dashboard/user/list-users');
        actions.setSubmitting(false);
      }
    } catch (error) {
      toast.error(error.message || 'Unable to register at the moment!');
      //     return this.setState({
      //     serverMessage: error.message,
      //     showSubmitLoader: false,
      //   });
      actions.setSubmitting(false);
    }
  };

  return (
    <Fragment>
      <div className='card p-3 mt-4 pb-5 create-user'>
        <div className='my-order'>
          <div className='row p-4'>
            <div className='col-6 col-md-9 col-xl-9'>
              <Link to='/dashboard/user/list-users'>
                <p className='head'>Go Back</p>
              </Link>
            </div>
            <div className=' col-6 col-md-3 col-xl-3'></div>
          </div>

          {/* account */}
          <div className='px-4 mb-5'>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, isSubmitting }) => (
                <Form>
                  <div className='row mt-5 px-4'>
                    <div className='col-md-5'>
                      <FormikControl
                        control='input'
                        name='firstName'
                        label='First Name'
                        id='m-input'
                      />
                    </div>
                    <div className='col-md-5'>
                      <FormikControl
                        control='input'
                        name='lastName'
                        label='Last Name'
                        id='m-input'
                      />
                    </div>
                  </div>
                  <div className='row mt-5 px-4'>
                    <div className='col-md-10'>
                      <FormikControl
                        control='select'
                        name='role'
                        label='User Role'
                        id='m-input'
                      >
                        <option value=''>Select User Role</option>
                        <option value='USER'>USER</option>
                        <option value='STORE_ADMIN'>STORE ADMIN</option>
                        <option value='SUPER'>SUPER</option>
                      </FormikControl>
                    </div>
                  </div>
                  <div className='row px-4'>
                    <div className='col-md-5'>
                      <FormikControl
                        control='input'
                        name='email'
                        label='Email Address'
                        id='m-input'
                      />
                    </div>
                    <div className='col-md-5'>
                      <FormikControl
                        control='input'
                        name='phoneNumber'
                        label='Phone Number'
                        id='m-input'
                      />
                    </div>
                  </div>

                  <div className='row px-4'>
                    <div className='col-md-5'>
                      <FormikControl
                        control='select'
                        name='country'
                        label='Country/Region'
                        id='m-input'
                      >
                        <option value=''>Select Country</option>
                        {getCountryList().map((country) => (
                          <option key={country} value={country}>
                            {country}
                          </option>
                        ))}
                      </FormikControl>
                    </div>
                    <div className='col-md-5'>
                      <FormikControl
                        control='input'
                        name='state'
                        label='Your State'
                        id='m-input'
                      />
                    </div>
                  </div>

                  <div className='row px-4 mt-3'>
                    <div className='col-md-5'>
                      <FormikControl
                        control='input'
                        name='city'
                        label='Your City'
                        id='m-input'
                      />
                    </div>
                    <div className='col-md-5'>
                      <FormikControl
                        control='input'
                        name='postalCode'
                        label='Postal Code'
                        id='m-input'
                      />
                    </div>
                  </div>

                  <div className='col-md-10 ml-2'>
                    <FormikControl
                      control='textarea'
                      name='address'
                      label='Address'
                      id='m-textarea'
                    />
                  </div>

                  <div className='row px-4 mt-3'>
                    <div className='col-md-5'>
                      <FormikControl
                        control='input'
                        name='password'
                        label='Password'
                        disabled
                        //  type='password'
                        placeholder='******'
                        id='m-input'
                      />
                    </div>
                    <div className='col-md-2 mt-2'>
                      <button
                        className='btn btn-primary mt-3  '
                        onClick={() => {
                          const password = generatePassword()[0] + '1';

                          setFieldValue('password', password);
                        }}
                        type='button'
                      >
                        <PasswordIcon />
                      </button>
                    </div>
                  </div>

                  <button
                    type='submit'
                    className='btn btn-primary float-right mt-3  rounded'
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <Loader
                        type='ThreeDots'
                        color='white'
                        height={30}
                        width={50}
                        timeout={10000000000000} //3 secs
                      />
                    ) : (
                      'Create New User'
                    )}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// export default ListOrders;

export default withDashboard(CreateUser);
