import React , {useState,useEffect} from 'react';
// import { Link } from 'react-router-dom';
import withDashboard from '../../hoc/withDashboard';
import {toast} from 'react-toastify';
import Loader from "react-loader-spinner";
import OrderService from '../../../../services/order/OrderService';

export const RfqOverview = () => {
 
  const [rfq, setRfq] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchRfqCount = async () =>{
    setLoading(true);

  const orderService = new OrderService();
  const response = await orderService.getRfqCount();

    try {
    if (response && response.status === 'SUCCESS'){
        setRfq(response.data);
        setLoading(false);
    } else {
      setLoading(false);
    }
  } catch (error) {
    toast.error(error.message );
  }
};

const [sentRfq, setSentRfq] = useState("");

  const fetchSentRfqCount = async () =>{
    setLoading(true);

    const orderService = new OrderService();
    const response = await orderService.getRfqCountBasedOnStatus("SENT");

    try {
    if (response && response.status === 'SUCCESS'){
        setSentRfq(response.data);
    } else {
      toast.error(response.msg);
    }
  } catch (error) {
    toast.error(error.message);
  }
};


const [acceptedRfq, setAcceptedRfq] = useState("");

  const fetchAcceptedRfqCount = async () =>{
    setLoading(true);

    const orderService = new OrderService();
    const response = await orderService.getInvoiceCountBasedOnStatus("APPROVED");

    try {
    if (response && response.status === 'SUCCESS'){
        setAcceptedRfq(response.data);
    } else {
      toast.error(response.msg);
    }
  } catch (error) {
    toast.error(error.message);
  }
};


const [declinedRq, setDeclinedRfq] = useState("");

  const fetchDeclinedRfqCount = async () =>{
    setLoading(true);

    const orderService = new OrderService();
    const response = await orderService.getInvoiceCountBasedOnStatus("CANCELED");

    try {
    if (response && response.status === 'SUCCESS'){
        setDeclinedRfq(response.data);
    } else {
      toast.error(response.msg);
    }
  } catch (error) {
    toast.error(error.message);
  }
};


const [noMatch, setnoMatch] = useState("");

  const fetchnoMatchCount = async () =>{
    setLoading(true);

    const orderService = new OrderService();
    const response = await orderService.getNoMatchCount();

    try {
    if (response && response.status === 'SUCCESS'){
        setnoMatch(response.data);
    } else {
      toast.error(response.msg);
    }
  } catch (error) {
    toast.error(error.message );
  }
};




useEffect(() => {
  fetchRfqCount().then();
  fetchSentRfqCount().then();
  fetchAcceptedRfqCount().then();
  fetchDeclinedRfqCount().then();
  fetchnoMatchCount().then();

}, []);



  return (
    <div className="dashboard">
     
     {!loading? (
       
        <div className="card p-5">

        <h3 className="h3 text-dark mb-5">Min.ng admin</h3>
        <div className="overview">
            <h3 className="text-dark ml-n4 mb-5">Rfq Overview</h3>
          <div className="row">
              <div className="col-md-2 mr-5 mt-3 m-single click">
                <div className="d-flex">
                    <div className="report">
                        <p>All Rfq's</p>
                        <p><b>{rfq}</b></p>
                    </div>
                </div>
              </div>

              <div className="col-md-2 mr-5 mt-3 m-single">
                <div className="d-flex">
                    <div className="report">
                        <p>All sent quotations</p>
                        <p><b>{sentRfq}</b></p>
                    </div>
                </div>
              </div>

              <div className="col-md-2 mr-5 mt-3 m-single">
                <div className="d-flex">
                    <div className="report">
                        <p>All Quotations accepted </p>
                        <p><b>{acceptedRfq}</b></p>
                    </div>
                </div>
              </div>
            
              <div className="col-md-2 mr-5 mt-3 m-single">
                <div className="d-flex">
                    <div className="report">
                        <p>All Quotations declined </p>
                        <p><b>{declinedRq}</b></p>
                    </div>
                </div>
              </div>

              <div className="col-md-2 mr-5 mt-3 m-single">
                <div className="d-flex">
                    <div className="report">
                        <p>Rfq's that did not match any item </p>
                        <p><b>{noMatch}</b></p>
                    </div>
                </div>
              </div>
            

     
          
    
     
  </div>



</div>


</div>
     ):(
              <Loader
              type="BallTriangle"
              height={100}
              width={100}
              timeout={3000} //3 secs
              className="loader"
              /> 
     )}
      
    </div>
  );
};

export default withDashboard(RfqOverview);