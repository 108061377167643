import axios from 'axios';
import RandomServices from './randomServices';

const setAuthTokenHeader = (token = null) => {
    const _token = RandomServices.getCookie(process.env.REACT_APP_TOKEN);
    localStorage.setItem(process.env.REACT_APP_TOKEN, _token);
    if (_token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${_token}`;
        console.log('Headers set');
    } else {
        delete axios.defaults.headers.common['Authorization'];
    }
};

export default setAuthTokenHeader;