/*eslint-disable*/
import React, { useState, useLayoutEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../layout/Header';
import Side from '../layout/SideBar';

const withDashboard = (Component) => {
    const Dashboard = (props) => {
        const history = useHistory();
        const [user] = useState(null);

        //   !Can do all good stuffs you do with react here
        //

        useLayoutEffect(() => {
            if (!!user) {
                history.push('/');
            }
        }, [user]);
        return ( 
            <Fragment >
             <Header/>

            <div className = 'row' >
                <div className = ' col-md-3 mt-5' >
                    <Side />
                </div> 
            
            <div className = 'col-md-9  mt-5 px-5' >
                <Component {...props }/> 
            </div> 
            </div> 
            </Fragment>
        );
    };

    return Dashboard;
};

export default withDashboard;