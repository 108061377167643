/*eslint-disable*/
import React, { Fragment, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';


import StoreService from "../../../../services/store/StoreService";
import RandomService from "../../../../utils/randomServices";

// import { Link } from 'react-router-dom';
// import withDashboard from '../../hoc/withDashboard';
import {toast} from 'react-toastify';
import {useHistory } from "react-router-dom";


const ListStores = () =>{

 


    const [loading,setLoading] = useState(false)

    const history = useHistory();
        const [stores, setStores] = useState([])


    const fetchStores = async () =>{
        setLoading(true);
      
        const storeService = new StoreService();
        const response = await storeService.getAllStore();
    try{
        if (response && response.status === 'SUCCESS'){
             toast.success( "Stores listed successfully!");
            setStores(response.data);
            setLoading(false);
            RandomService.initDatatable('list-stores');
        } else {
            toast.error(response.msg || "Unable to list stores!");
            setLoading(false);
            RandomService.initDatatable('list-stores');
        }

        }catch (error) {
            toast.error(error.message || 'Unable to list stores at the moment!');
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchStores().then();
      }, []);

   
    
      const viewStore = (storeId)=> {
        history.push('/dashboard/store/store-detail/' + storeId);
    
      }

      const viewProducts = (storeId)=> {
        history.push('/dashboard/products/list-products/' + storeId);
    
      }
    
   
    return(
        <Fragment>
             <div className="card px-3 py-5 px-4 list-store">
                    <div>
                        <div className='row'>
                            <div className='col-sm-12 col-md-4 col-xl-4'>
                                <h4>List of Stores</h4>
                            </div>
                            <div className='col-sm-12 col-md-4 col-xl-4 mt-5 mt-md-0 mb-md-0 mb-4'>
                               {/*  <label htmlFor="filterProduct">Filter Products</label>
                               <select className="form-control" id="filterProduct" onChange={this.filterProducts}>
                                   <option>ALL</option>
                                   <option>ONLINE</option>
                                   <option>OFFLINE</option>
                                   <option>PENDING</option>
                                   <option>REJECTED</option>
                               </select>*/}
                            </div> 
                        </div>

                        <div>
                            <div className="body mt-5">

                            {loading?
                                <div className="text-center mt-1 mb-3" >
                                <span className="fa fa-spin fa-spinner fa-2x"></span>&nbsp;
                                <br />
                                    <span> Loading, Please wait...</span>
                            </div> : null
                                }
                                { !loading ? <div className="table-responsive">
                                    <table id="list-stores"
                                           className="table table-bordered table-hover table-striped dataTable"
                                           cellSpacing="0" width="100%">
                                        <thead>
                                        <tr>
                                            <th>S/N</th>
                                            <th>Company Name</th>
                                            {/* <th>Company Size</th> */}
                                            <th>Established</th>
                                            <th>Email Address</th>
                                            <th>Country</th>
                                            <th>Created</th>
                                            <th>Updated</th>
                                            <th>Actions</th>
                                        </tr>
                                        </thead>
                                        <tfoot>
                                        <tr>
                                            <th>S/N</th>
                                            <th>Company Name</th>
                                            {/* <th>Company Size</th> */}
                                            <th>Established</th>
                                            <th>Email Address</th>
                                            <th>Country</th>
                                            <th>Created</th>
                                            <th>Updated</th>
                                            <th>Actions</th>
                                        </tr>
                                        </tfoot>
                                        <tbody>

                                {stores.map((store,index) => (
                                    <tr key={store.id + "_user_" + index} id={store.id}>
                                        <td>{index + 1}</td>
                                    <td>{store.companyName}</td>
                                    {/* <td>{user.lastName}</td> */}
                                    <td>{store.yearEstablished}</td>
                                    <td>{store.email}</td>
                                    <td>{store.country}</td>
                                    <td>{store.createdAt}</td>
                                    <td>{store.updatedAt}</td>
                                    <td>
                          
                                <div className="btn-group">
                                  <button type="button" className="btn btn-success">Action</button>
                                  <button type="button" className="btn btn-success dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span className="sr-only">Toggle Dropdown</span>
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <a className="dropdown-item" href="" onClick={() => viewStore(store._id)}>View Store</a>
                                    <a className="dropdown-item" href="" onClick={() => viewProducts(store._id)}>View Products</a>
                                  
{/*                                     
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#!">Disable</a> */}
                                  </div>
                                </div>
                          </td>
                                </tr>
                                ))}
                                       

                                        </tbody>

                                    </table>
                                </div> : null

                                }
    </div>




</div>
</div>
      </div>
    </Fragment>
  );
};

export default ListStores;
