/* eslint-disable */
import React, {Component, Fragment,useEffect,useState} from 'react';
import StoreService from "../../../../services/store/StoreService";
import RandomService from "../../../../utils/randomServices";
import { toast } from 'react-toastify';
// import * as query_string from "query-string";
import withDashboard from '../../hoc/withDashboard';
import {useParams} from "react-router-dom";
import { Link } from 'react-router-dom';
import {useHistory } from "react-router-dom";


const ViewUserStores = () => {

    const {userId} =  useParams();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [stores, setStores] = useState([])

    const  getStores = async () => {
        setLoading(true);
        const storeService = new StoreService();
        const response = await storeService.getUserStore(userId);
        console.log('response ', response);
        if(response && response.status === 'SUCCESS') {

            console.log("response: " + JSON.stringify(response.data));
            setStores(response.data);
            // this.setState({stores: response.data, loading: false});
            // const params = query_string.parse(this.props.location.search);
            // // console.log('QUERY PARAMS ', params, (params.store && !!params.triggerEdit === true));
            // if(params.store && !!params.triggerEdit === true) {
            //     $('#btn_' + params.store).trigger('click');
            // }

            RandomService.initDatatable('list-stores');

        } else {
            toast.error(response.msg || "Unable to get stores!");
            setLoading(false);
            RandomService.initDatatable('list-stores');
        }
    }

    

    // const triggerStoreUpdate = (store) => {
    //     console.log('Event to update store, ', store);
    //     this.props.handleUpdateStore(store);
    // }
    const accessStore = (storeId) => {
        history.push('/dashboard/store/store-detail/' + storeId)
    }

    const createStore = () => {
        history.push('/dashboard/store/create-store/' + userId);
    }

    useEffect(() => {
        getStores();
      }, []);

    

       return (
           <Fragment>
               <div className='card p-3 mt-4 pb-5 view-stores'>

                
            <div className='row'>
                    <div className='col-6 col-md-8 col-xl-8'>
                    <Link to={'/dashboard/user/view-user/'+ userId}>
                        <p className='head'><b>Go Back</b></p>
                    </Link>
                </div>

                <div className=' col-sm-12 col-md-4 col-xl-4 mt-md-4'>
                        <button onClick={() => createStore(userId)} className='m-btn m-btn-primary float-right m-btn-small-round bg-primary'>
                                Create New Store
                        </button>
                </div>
           
          </div>



               <div className='table-responsive'>

                   
          <h4 className='mb-5 ml-4'>List of Stores</h4>
                   <table id='list-stores'
                    className='table table-bordered mt-4 table-hover table-striped dataTable'
                    cellSpacing='0'
                    width='100%'>
                       <thead>
                       <tr>
                           <th>S/N</th>
                           <th>Company Name</th>
                           <th>Company Size</th>
                           <th>Established</th>
                           <th>Branch Count</th>
                           <th>Actions</th>
                       </tr>
                       </thead>

                       <tfoot>
                      <tr>
                            <th>S/N</th>
                           <th>Company Name</th>
                           <th>Company Size</th>
                           <th>Established</th>
                           <th>Branch Count</th>
                           <th>Actions</th>
                      </tr>
                    </tfoot>
                       {loading && stores.length ?
                           (<tbody>
                       {stores.map((store, index) => (
                           <tr key={store._id + '___orderedItem___' + index} id={store._id}>
                               <td>
                                   {index + 1}
                               </td>
                               <td>{store.companyName}</td>
                               <td>{store.employeeNumber}</td>
                               <td>{store.yearEstablished}</td>
                               <td>{store.Count || 0}</td>
                               <td>
                                   <button className="btn btn-success btn-sm" onClick={() => accessStore(store._id)}>Access Store</button>&nbsp;
                                   {/* <button className="btn btn-info btn-sm" id={'btn_' + store._id } onClick={() => this.triggerStoreUpdate(store)}>
                                       <i className="fa fa-pencil"></i>&nbsp;{ store.percentageCompletion === 100 ? "View/Update Setup" : "Continue Setup"}</button> */}
                               </td>
                           </tr>
                       ))
                       }
                       </tbody>)
                           : null
                       }
                   </table>
               </div>
                   {/* {!loading && !stores.length ?
                       (<div className="text-center">
                       <div>
                           <img src={global.no_record} className="mx-auto d-block"/>
                       </div>
                        </div>) : null} */}

                   {/* {this.state.loading ?
                       (<div className="text-center">
                       <div>
                           <span className="fa fa-spinner fa-spin fa-3x"></span>
                       </div>
                        </div>) : null} */}
           </div>
           </Fragment>
       );
   }


// export default ListStoreTable;
export default withDashboard(ViewUserStores);
