/* eslint-disable */
import React, { useState, useEffect } from 'reactn';
// import '../../product/css/dropdown.css';
import { toast } from 'react-toastify';

import DropdownTreeSelect from 'react-dropdown-tree-select';
import { Editor } from '@tinymce/tinymce-react';
import ProductService from '../../../../../services/product/ProductService';
import FormService from '../../../../../services/form/FormService';
import RandomService from '../../../../../utils/randomServices';
import { useHistory } from 'react-router-dom';
import * as $ from 'jquery';
import Croppiee from '../../../../common/croppie/Croppie';
import Select from 'react-select';
import StoreService from '../../../../../services/store/StoreService';

const ProductDetails = (props) => {
  const history = useHistory();
  const dynamic = {
    name: '',
    value: '',
  };
  const [categoryName, setCategoryName] = useState(null);
  const [categoryId, setCategoryId] = useState('');
  const [formNow, setFormNow] = useState({});
  const [formId, setFormId] = useState('');
  const [loadingImg, setLoadingImg] = useState(false);
  const [loadingStore, setLoadingStore] = useState(true);

  const [dynamicForm, setDynamicForm] = useState([]);
  useEffect(() => {
    if (props && props.product._id) {
      setCategoryId(props.product.categoryId._id);
      setCategoryName(props.product.categoryId.categoryName);
    }
    if (props && props.product.form_id) {
      setFormId(props.product.form_id || null);
    }
    if (
      props.product.category_form_fields &&
      props.product.category_form_fields.formName &&
      props.product.category_form_fields.constructor.name.toLowerCase() ===
        'object'
    ) {
      setFormNow(props.product.category_form_fields);
    }
    if (
      props.product.additional_form_fields &&
      props.product.additional_form_fields.length &&
      props.product.additional_form_fields.constructor.name.toLowerCase() ===
        'array'
    ) {
      setDynamicForm(props.product.additional_form_fields);
      setCategoryId(props.product.categoryId._id);
      setTimeout(() => {
        props.product.additional_form_fields.forEach((data, i) => {
          $(`#name__${i}`).val(data.name);
          $(`#value__${i}`).val(data.value);
        });
      }, 5000);
    } else {
      setDynamicForm([dynamic]);
    }
  }, []);

  const placeHolder = {
    placeholder: 'Select Category',
    inlineSearchPlaceholder: 'Choose Category',
    noMatches: 'Category not found',
    label: 'CATEGORY',
    labelRemove: 'Change Category',
  };

  const [product, setProduct] = useState({ ...props.product });
  const [allOrder] = useState([]);
  const [orderSet, setOrderSet] = useState({ name: 'direct payment order' });
  const handleProductChange = (event) => {
    event.preventDefault();

    if (event.target.name === 'orderTypeId' && !!event.target.value) {
      const _orderSet =
        orderTypes.filter((order) => order._id === event.target.value)[0] || {};

      setOrderSet(_orderSet);

      if (allOrder.some((order) => order.id === event.target.value)) {
        alert('found');
      } else {
        allOrder.push(_orderSet);
        setProduct({
          ...product,
          [event.target.name]: JSON.stringify(allOrder),
        });
      }
    } else if (
      event.target.name === 'has_product_sample' &&
      event.target.value === false
    ) {
      setProduct({ ...product, product_sample_price: 0 });
    } else {
      setProduct({ ...product, [event.target.name]: event.target.value });
    }
  };
  const [loadingLogistics, setLoadingLogistics] = useState(false);
  const [activeLogistics, setActiveLogistics] = useState([]);

  const getActiveLogistics = async (value) => {
    const productService = new ProductService();
    const response = await productService.getAttachedLogisticsOfShipping(value);
   
    if (response && response.status === 'SUCCESS') {


      response.data.forEach((data) => {
        data.label = data.logistic.company_name;
        data.value = data.logistic._id;
      });
      
     

      setActiveLogistics(response.data);
      setLoadingLogistics(false);
    } else {
      setLoadingLogistics(false);
    }
  };

 

  
  const triggerShippingClassChange = (event) => {
    event.preventDefault();
    setProduct({ ...product, [event.target.name]: event.target.value });
    setActiveLogistics([]);
    setLoadingLogistics(true);
    getActiveLogistics(event.target.value).then();
  };

  const handleNegativeValue = (e) => {
    if (Math.sign(parseFloat(e.target.value || 0)) < 0) {
      e.target.value = Math.abs(parseFloat(e.target.value || 0));
      setProduct({ ...product, [e.target.name]: e.target.value });
    } else {
      setProduct({ ...product, [e.target.name]: e.target.value });
    }
  };

  const onChangeCategory = (currentNode, selectedNodes, e) => {
    setCategoryName(currentNode.label);
    setCategoryId(currentNode.value);
    setFormNow(currentNode.form || {});
    setFormId(currentNode.form && currentNode.form._id);
    toast.success('Category updated to ' + currentNode.label);
  };
  const onAction = (node, action) => {};
  const onNodeToggle = (currentNode) => {};

  const [orderTypes, setOrderTypes] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const getOrderTypes = async () => {
    const currentUser = await RandomService.getAuthUser();
    if (currentUser) {
      setCurrentUser(currentUser.id);
      const productService = new ProductService();
      const response = await productService.getOrderTypes();

      if (response && response.status === 'SUCCESS') {
        const _orderTypes = response.data.map((orderType) => ({
          label: `${orderType.name} (${orderType.acronym})`,
          value: {type : orderType.id, name: orderType.name},
        }));


        setOrderTypes(_orderTypes || []);
        // const _orderSet =
        //   _orderTypes.filter(
        //     (order) => order.value === product.orderTypeId
        //   )[0] || {};

        // setOrderSet(_orderSet);
      } else {
      }
    } else {
      // user not detected, logout
    }
  };

  // const [selectedorderType, setSelectedOrderType] = useState(product.orderTypeId)
  // const getSelectedOrderType = () =>{

    
    
  //     const _ordertype = selectedorderType.map((order) =>({
  //       label: `${order._id}`,
  //       value: `${order.name}`
  //     }));
  //     console.log("products: " + selectedorderType);

  //     // setSelectedOrderType(_ordertype || []);
  // }
  
  const getFormById = async (id) => {
    const formService = new FormService();
    const response = await formService.getForm(id);
    if (response && response.status === 'SUCCESS') {
      // setProduct({...product, formId: id});
    } else {
    }
  };
  const [weightUnits, setWeightUnits] = useState([]);
  const getWeightUnits = async () => {
    const productService = new ProductService();
    const response = await productService.getWeightUnits();
    if (response && response.status === 'SUCCESS') {
      setWeightUnits(response.data);
    }
  };
  const [dimensionUnits, setDimensionUnits] = useState([]);
  const getDimensionUnits = async () => {
    const productService = new ProductService();
    const response = await productService.getDimensionUnits();
    if (response && response.status === 'SUCCESS') {
      setDimensionUnits(response.data);
    }
  };
  const [categories, setCategories] = useState([]);
  const getSpecialCategories = async () => {
    const categoryService = new ProductService();
    const response = await categoryService.getSpecialCategories();
    if (response && response.status === 'SUCCESS') {
      setCategories(response.data);
    } else {
      toast.error(response.msg || 'Unable to fetch categories!');
    }
  };
  const [logistics, setLogistics] = useState([]);
  const getLogistics = async () => {
    const productService = new ProductService();
    const response = await productService.getShippingLogistics();
    if (response && response.status === 'SUCCESS') {
      setLogistics(response.data);
    } else {
      toast.error(response.msg || 'Unable to fetch logistics!');
    }
  };
  const [shippingClasses, setShippingClasses] = useState([]);
  const getShippingClasses = async () => {
    if (props.product.shipping_class) {
      getActiveLogistics(props.product.shipping_class).then();
    } // just get the preselected shipping
    const productService = new ProductService();
    const response = await productService.getShippingClasses();
    if (response && response.status === 'SUCCESS') {
      setShippingClasses(response.data);
      console.log(JSON.stringify(response.data));
    } else {
      toast.error(response.msg || 'Unable to fetch shipping class!');
    }
  };

  const [selectedOption, setSelectedOption] = useState(null);
  const [stores, setStores] = useState([]);
  const [defaultedTo, setDefaultedTo] = useState({});
  const getStores = async () => {
    setLoadingStore(true);
    const storeService = new StoreService();
    const _currentUser = await RandomService.getAuthUser();

    const response = await storeService.getUserStore(_currentUser.id);

    if (response && response.status === 'SUCCESS') {
      const stores_ = response.data;
      stores_.forEach((store) => {
        store.value = store._id;
        store.label = store.companyName;
      }); /*
            if(props.product.storeId) {
                setSelectedOption(stores_[0]);
            }*/
      const _default = stores_.filter(
        (store) => store._id === props.product.storeId
      );
      setSelectedOption(_default[0]);
      setStores(stores_);
      setDefaultedTo(_default[0]);

      setLoadingStore(false);
    } else {
      setLoadingStore(false);
    }
  };
  useEffect(() => {
    getOrderTypes().then();
    getDimensionUnits().then();
    getWeightUnits().then();
    getSpecialCategories().then();
    getLogistics().then();
    getShippingClasses().then();
  }, []);

  const handleDescEditorChange = (content) => {
    setProduct({ ...product, productDescription: content + '' });
  };

  const handleDynamicFormChange = (field, e, key) => {
    field[key] = e.target.value;
  };

  const handleCategoryFormChange = (field, e) => {
    field['value'] = e.target.value;
  };

  const addNewField = () => {
    dynamicForm.push(dynamic);

    setDynamicForm([...dynamicForm]);
  };
  const removeDynamicField = (index) => {
    if (dynamicForm.length === 1) {
      return false;
    } else {
      dynamicForm.splice(index, 1);
      setDynamicForm([...dynamicForm]);
    }
  };
  const [loading, setLoading] = useState(false);
  const handleProductSubmit = (e) => {
    e.preventDefault();

    
    // if (product.storeId === '1234567890123456789' || product.storeId === null) {
    //   return toast.error('Select Store for this product.');
    // }
    if (!product.dimension_unit) {
      product.dimension_unit = null;
    }
    if (!product.weight_unit) {
      product.weight_unit = null;
    }
    if (!product.orderTypeId) {
      product.orderTypeId = null;
    }
    if (!product.form_id) {
      product.form_id = null;
    }

    if (!product.productName) {
      return toast.error('Product name is required');
    } else if (!product.unit_price) {
      return toast.error('Product unit price is required');
    } else if (!product.coverImage) {
      return toast.error('Upload an image for your product');
    } else if (!categoryId) {
      return toast.error('Product category is required');
    } else if (!product.storeId) {
      return toast.error('Store Id is not set, consider refreshing this page!');
    } else if (!product.productDescription) {
      return toast.error('Product description is required');
    } else if (product.weight && !product.weight_unit) {
      return toast.error('Product weight(unit) is required');
    } else if (
      (product.length || product.breadth || product.height) &&
      !product.dimension_unit
    ) {
      return toast.error('Product dimension(unit) is required');
    } else {
      if (dynamicForm && dynamicForm.length) {
        dynamicForm.forEach((field, index) => {
          if (!field.name || !field.value) {
            dynamicForm.splice(index, 1);
          }
        });
      }
      if (formNow && formNow.fields && formNow.fields.length) {
        let valid = true;
        const validationArray = JSON.parse(JSON.stringify(formNow.fields));
        validationArray.reverse().forEach((field) => {
          if (field.required && !field.value && field.type !== 'checkbox') {
            valid = false;
            return toast.error('Please enter ' + field.label + '!');
          }
          if (field.required && field.regex) {
            const regex = new RegExp(field.regex);
            if (regex.test(field.value) === false) {
              valid = false;
              return toast.error('Error: ' + field.errorText);
            }
          }
          if (field.required && field.type === 'checkbox') {
            if (field.values.filter((r) => r.selected).length === 0) {
              valid = false;
              return toast.error('Error ' + field.label);
            }
          }
        });
        if (!valid) {
          return false;
        }
      }

      setLoading(true);
      proceedToSaveProduct(formNow, dynamicForm, product).then();
    }
  };
  const proceedToSaveProduct = async (_form, _dynamic, _product) => {
    const all_fields = {
      ..._product,
    };
    all_fields['categoryId'] = categoryId;
    all_fields['form_id'] = formId || null;
    all_fields['storeOwner'] = currentUser;
    all_fields['category_form_fields'] = _form || {};
    all_fields['additional_form_fields'] = _dynamic || [];

    if (all_fields._id) {
      updateProduct(all_fields).then();
    } else {
      try {
        console.log("Product data: " + JSON.stringify(all_fields));
        const productService = new ProductService();
        const response = await productService.addProduct(all_fields);
        if (response && response.status === 'SUCCESS') {
          setLoading(false);
          setTimeout(() => {
            history.push('/dashboard/products/list-products/' + props.product.storeId);
          }, 2000);
          toast.success('Product created successfully!');
        } else {
          setLoading(false);
          toast.error(response.msg || 'Unable to create product!');
        }
      } catch (e) {
        toast.error(
          e.message || 'Unable to process product, please try again!'
        );
      }
    }
  };

  async function updateProduct(all_fields) {
    try {
      const productService = new ProductService();
      const response = await productService.updateProduct(
        all_fields._id,
        all_fields
      );
      if (response && response.status === 'SUCCESS') {
        setLoading(false);
        toast.success(response.msg || 'Product updated successfully!');
      } else {
        setLoading(false);
        toast.error(response.msg || 'Unable to update product!');
      }
    } catch (e) {
      toast.error(
        e.message || 'Unable to process product update, please try again!'
      );
    }
  }

  const getUploadEvent = (e) => {
    setProduct({ ...product, coverImage: e.userObject.coverImage });
  };
  const startLoading = (event) => {
    if (event === 'showLoading') {
      setTimeout(() => {
        setLoadingImg(false);
      }, 3000);
      setLoadingImg(true);
    }
  };

  const deleteFromFireBase = (file) => {
    const imageName = file.split(
      'https://firebasestorage.googleapis.com/v0/b/min-user-portal.appspot.com/o/minng_files%2F'
    )[1];
    const image = imageName.split('?alt=');

    RandomService.deleteImages(image[0]);
  };
  const removeImageAndRestorePicker = (itemKey, position = null) => {
    let imageInFireBase;
    if (position) {
      imageInFireBase = product[itemKey][position];
      product[itemKey][position] = null;
    } else {
      imageInFireBase = product[itemKey];
      product[itemKey] = null;
      setProduct({ ...product, coverImage: null });
    }
    // deleteFromFireBase(imageInFireBase); // because the product might not be saved
  };
  const [croppie] = useState({
    id: 'displayCoverImage',
    data: product,
    key: 'coverImage',
    config: {
      vheight: 250,
      vwidth: 350,
      fheight: 450,
      fwidth: 'auto',
      enableResize: false,
      profile: false,
    },
  });

  const handleStoreChange = (selectedOption) => {
    setSelectedOption(selectedOption);

    setProduct({ ...product, storeId: selectedOption._id });
  };
  const pushSelectedLogistic = (e) => {
    const s = [];
    e.forEach((r) => {
      s.push(r.value);
    });
    setTimeout(() => {
      setProduct({ ...product, logistics: s });
    }, 1000);
  };

  const handleOrderTypeChange = (data = []) => {
    if (!data) {
      return setProduct({ ...product, orderTypeId: [] });
    }
    const _ot = data.map((v) => (v?.value ));


    setProduct({ ...product, orderTypeId: _ot });
  };
  return (
    <React.Fragment>
      <div className='card ' id='multi-form-card'>
        <div className='card-header'>
          <div className='row'>
            <div className='col-sm-8'>
              <h6 className='text-dark h6'><b>Product Details</b></h6>
            </div>
            
          </div>
        </div>
        {/*<pre>{JSON.stringify(product, null, 2) }</pre>*/}

        <div className='pl-5 pt-5 create-product'>
          <form onSubmit={(e) => handleProductSubmit(e)}>
            <div className='row'>
              <div className='col-md-12 col-lg-6 mt-md-3  col-sm-12'>
                <div className='form-group'>
                  <label htmlFor='productName'>Product Name</label>
                  <label className='text-danger'>*</label>
                  <input
                    type='text'
                    className='form-control'
                    value={product.productName}
                    id='productName'
                    onChange={(event) => handleProductChange(event)}
                    placeholder='Enter product name'
                    name='productName'
                  />
                </div>
              </div>
              <div className='col-md-12 col-lg-6 mt-md-3  col-sm-12'>
                <div className='form-group'>
                  <label className='control-label' htmlFor='unit_price'>
                    Unit price
                  </label>
                  <label className='text-danger'>*</label>
                  <div className='input-group'>
                    <input
                      className='form-control'
                      value={product.unit_price}
                      onChange={(event) => handleNegativeValue(event)}
                      id='unit_price'
                      name='unit_price'
                      placeholder='Unit price'
                      type='number'
                      /*onBlur={(e) => handleNegativeValue(e)} onKeyUp={(e) => handleNegativeValue(e)}*/
                    />
                    <div className='input-group-append'>
                      <span className='input-group-text'>NGN</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-12 col-lg-6 mt-md-3 col-sm-12'>
                <div className='form-group'>
                  <label htmlFor='productCategory'>Product Category</label>
                  <label className='text-danger'>*</label>

                  <div className=''>
                    <DropdownTreeSelect
                      data={categories}
                      onChange={(e, r, event) => onChangeCategory(e, r, event)}
                      onAction={onAction}
                      mode='radioSelect'
                      className='bootstrap-demo'
                      texts={placeHolder}
                    />
                  </div>
                  {!!categoryId ? (
                    <div className=''>
                      <span className='' style={{ fontSize: '10px' }}>
                        Selected Category:{' '}
                        <strong
                          className='text-success'
                          style={{ fontSize: '13px' }}
                        >
                          {categoryName}
                        </strong>
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-12 col-lg-6 mt-md-3 col-sm-12'>
                {/* <div className="form-group">
                       <label htmlFor="modelName">Model Name</label>
                       <input type="text" className="form-control" value={product.modelName}  onChange={(event) => handleProductChange(event)}  id="modelName" placeholder="Enter model name" name="modelName" />
                     </div>*/}

                <div className='form-group'>
                  <label htmlFor='productQuantity'>Available Units</label>
                  <label className='text-danger'>*</label>
                  <input
                    type='number'
                    className='form-control'
                    id='productQuantity'
                    value={product.units}
                    onChange={(e) => handleNegativeValue(e)}
                    /*onKeyUp={(e) => handleNegativeValue(e)} onChange={(event) => handleProductChange(event)}*/
                    placeholder='Available Units'
                    name='units'
                  />
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-12 col-lg-6 mt-md-3 col-sm-12'>
                <div className='form-group'>
                  <label className='control-label' htmlFor='orderType'>
                    Order Type
                  </label>
                  <label className='text-danger'>*</label>

                  <Select
                    options={orderTypes}
                    // defaultValue={selectedorderType}
                    // value={product.logistics}
                    isSearchable={true}
                    onChange={handleOrderTypeChange}
                    isMulti
                    placeholder='Select Order Types'
                  />

                  {/* <select
                    className='form-control'
                    onChange={(event) => handleProductChange(event)}
                    name='orderTypeId'
                  >
                    <option value='' disabled={true}>
                      Select Order Type
                    </option>
                    {orderTypes.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.name}
                      </option>
                    ))}
                  </select> */}

                  {/* {orderTypes.map((option) => (
                                <div>
                                    <input type="checkbox" name="orderTypeId" key={option._id} id={option.name} value={option._id} onChange={(event) => handleProductChange(event)}/> <label htmlFor={option.name}>{option.name}</label>
                                    </div>
                            ))} */}
                </div>
              </div>
              <div className='col-md-12 col-lg-6 mt-md-3 col-sm-12'>
                <label htmlFor='shippingClass'>Shipping Class</label>
                <select
                  className='form-control'
                  id='shippingClass'
                  value={
                    product.shippingClass &&
                    product.shippingClass.constructor.name.toLowerCase() ===
                      'array'
                      ? product.shippingClass[0]
                      : product.shippingClass || ''
                  }
                  onChange={(event) => triggerShippingClassChange(event)}
                  name='shippingClass'
                >
                  <option value='' disabled={true}>
                    Shipping Class
                  </option>
                  {shippingClasses.map((option) => (
                    <option
                      key={option._id + '_shipping_classes__'}
                      value={option._id}
                    >
                      {"(" +option.name+ ")" + option.description}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-12 col-lg-6 mt-md-3 col-sm-12'>
                <label htmlFor='shippingMethod'>Shipping Method</label>
                {/* <select className="form-control" disabled={!product.shippingClass} id="shippingMethod" value={product.shippingMethod || ""}  onChange={(event) => handleProductChange(event)} name="shippingMethod">
                           <option value="">Shipping Method</option>
                           {logistics.map((option) => (
                               <option  key={option._id + '_logistics__'} value={option._id}>
                                   {option.company_name}
                               </option>
                           ))}
                       </select>*/}

                <Select
                  isDisabled={!product.shippingClass}
                  className=''
                  options={activeLogistics}
                  defaultValue={product.logistics}
                  // value={product.logistics}
                  isSearchable={true}
                  onChange={pushSelectedLogistic}
                  isMulti
                  isLoading={loadingLogistics}
                  placeholder='Select shipping logistics'
                />
              </div>
              <div className='col-md-12 col-lg-6 mt-md-3 col-sm-12'>
                <div className='form-group'>
                  <label className='control-label' htmlFor='returnPolicy'>
                    Return Policy
                  </label>
                  <select
                    className='form-control'
                    value={product.returnPolicy}
                    onChange={(event) => handleProductChange(event)}
                    name='returnPolicy'
                  >
                    <option value=''>Select Return Policy</option>
                    <option value='7 days'>7 days</option>
                    <option value='14 days'>14 days</option>
                    <option value='28 days'>28 days</option>
                    <option value='30 days'>30 days</option>
                  </select>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-12 col-md-12 mt-md-3 col-lg-6 '>
                <label className='control-label' htmlFor='minOrder'>
                  Minimum Order(MOQ)
                </label>
                <input
                  className='form-control'
                  value={product.minOrder}
                  onChange={(event) => handleNegativeValue(event)}
                  id='minOrder'
                  name='minOrder'
                  placeholder='MOQ'
                  type='number'
                />
              </div>
              <div className='col-sm-12 col-md-12  col-lg-6 '>
                <div className='row'>
                  <div className='col-md-12 col-lg-6  mt-md-3 col-sm-12'>
                    <label htmlFor='productWeight'>Weight</label>
                    <input
                      type='number'
                      className='form-control'
                      value={product.weight}
                      onChange={(event) => handleNegativeValue(event)}
                      id='productWeight'
                      placeholder='Product weight'
                      name='weight'
                    />
                  </div>
                  <div className='col-md-12 col-lg-6 mt-md-3 col-sm-12'>
                    <label htmlFor='weightUnit'>Unit Measurement</label>
                    <select
                      className='form-control'
                      value={product.weight_unit || ''}
                      id='weightUnit'
                      onChange={(event) => handleProductChange(event)}
                      name='weight_unit'
                    >
                      <option value=''>Weight Measurement</option>
                      {weightUnits.map((option) => (
                        <option
                          className='text-capitalize'
                          key={option._id}
                          value={option._id}
                        >
                          {option.description}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            {!!categoryId ? (
              <>
                <div className='row mt-2'>
                  <div className='col-md-6 col-sm-12'>
                    <label htmlFor='productLength'>Length</label>
                    <input
                      type='number'
                      className='form-control'
                      value={product.length}
                      onChange={(event) => handleNegativeValue(event)}
                      id='productLength'
                      placeholder='Product length'
                      name='length'
                      /*onBlur={(e) => handleNegativeValue(e)} onKeyUp={(e) => handleNegativeValue(e)}*/
                    />
                  </div>
                  <div className='col-md-6 col-sm-12'>
                    <label htmlFor='productBreadth'>Breadth</label>
                    <input
                      type='number'
                      className='form-control'
                      value={product.breadth}
                      id='productBreadth'
                      onChange={(event) => handleNegativeValue(event)}
                      /*onBlur={(e) => handleNegativeValue(e)} onKeyUp={(e) => handleNegativeValue(e)}*/
                      placeholder='Product breadth'
                      name='breadth'
                    />
                  </div>
                </div>
                <div className='row mt-2'>
                  <div className='col-md-6 col-sm-12'>
                    <label htmlFor='productBreadth'>Height</label>
                    <input
                      type='number'
                      className='form-control'
                      id='productWidth'
                      value={product.height}
                      onChange={(event) => handleNegativeValue(event)}
                      /*onBlur={(e) => handleNegativeValue(e)} onKeyUp={(e) => handleNegativeValue(e)}*/
                      placeholder='Product height'
                      name='height'
                    />
                  </div>
                  <div className='col-md-6 col-sm-12'>
                    <label htmlFor='dimensionUnit'>Unit Dimension</label>
                    <select
                      className='form-control'
                      value={product.dimension_unit || ''}
                      onChange={(event) => handleProductChange(event)}
                      name='dimension_unit'
                    >
                      <option value=''>Unit Dimension</option>
                      {dimensionUnits.map((option) => (
                        <option
                          className='text-capitalize'
                          key={option._id + '_unit_dim_'}
                          value={option._id}
                        >
                          {option.description}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </>
            ) : null}

            <div className='row mt-4'>
              <div className='col-12'>
                <label>
                  Product Cover Image<label className='text-danger'>*</label>
                </label>
                <div className='text-center'>
                  {!product.coverImage && !loadingImg ? (
                    <div>
                      <Croppiee
                        croppieData={croppie}
                        updateUI={getUploadEvent}
                        showLoad={startLoading}
                      ></Croppiee>
                    </div>
                  ) : null}

                  {!product.coverImage && loadingImg ? (
                    <div>
                      <span className='fa fa-spin fa-spinner fa-3x'></span>
                      <br />
                      Saving...
                    </div>
                  ) : null}

                  {!!product.coverImage ? (
                    <div className='media-left'>
                      <img
                        src={product.coverImage}
                        className='user-photo media-object'
                        alt='Product Image'
                      />

                      <div className='media-body'>
                        <p>
                          Upload Image.
                          <br /> <em>Image should be at least 250px x 250px</em>
                          <br />
                          <span className='text-success'>
                            This is product featured image, ensure it's clear
                            and well represent the product
                          </span>
                        </p>

                        <button
                          type='button'
                          className='btn btn-outline-dark'
                          id='btn-upload-photo'
                          onClick={() =>
                            removeImageAndRestorePicker('coverImage', null)
                          }
                        >
                          Change Image
                        </button>
                        <input
                          type='file'
                          id='filePhoto'
                          className='sr-only'
                          onClick={() =>
                            removeImageAndRestorePicker('coverImage', null)
                          }
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className='row pt-5'>
              <div className='col-md-12 col-sm-12'>
                <label>Product Description</label>
                <label className='text-danger'>*</label>
                <div className='mt-2'>
                  <Editor
                    apiKey='bwqsd8v78hucvd5gz8j8pa4mzav024zz4k92ehthyl0md1t1'
                    initialValue={`${product.productDescription}` || '1'}
                    init={{
                      height: 300,
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image charmap preview anchor',
                        'searchreplace',
                        'insertdatetime media table paste code help wordcount',
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                                 alignleft aligncenter alignright alignjustify | \
                                 bullist numlist outdent indent | removeformat',
                    }}
                    onEditorChange={handleDescEditorChange}
                  />
                </div>
              </div>
            </div>

            {!!product.orderTypeId &&
            orderSet.name.toLowerCase() !== 'direct payment order' ? (
              <>
                {' '}
                <div className='row mt-3'>
                  <div className='col-sm-12 col-md-6'>
                    <label htmlFor='negotiable' className='mb-2'>
                      Is the price negotiable ?
                    </label>
                    <div className='row mt-2 px-3'>
                      <div className='d-inline-flex'>
                        <label className='customized-radio'>
                          Yes
                          <input
                            type='radio'
                            name='negotiable'
                            defaultChecked={product.negotiable === true}
                            value={true}
                            onChange={(event) => handleProductChange(event)}
                          />
                          <span className='checkmark'></span>
                        </label>
                        &nbsp;&nbsp;&nbsp;
                        <label className='customized-radio'>
                          No
                          <input
                            type='radio'
                            name='negotiable'
                            defaultChecked={product.negotiable === false}
                            value={false}
                            onChange={(event) => handleProductChange(event)}
                          />
                          <span className='checkmark'></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-12 col-md-6'>
                    <label className=''>Is the product customizable ?</label>
                    <div className='row mt-2 px-3'>
                      <div className='d-inline-flex'>
                        <label className='customized-radio'>
                          Yes
                          <input
                            type='radio'
                            name='customizable'
                            defaultChecked={product.customizable === true}
                            value={true}
                            onChange={(event) => handleProductChange(event)}
                          />
                          <span className='checkmark'></span>
                        </label>
                        &nbsp;&nbsp;&nbsp;
                        <label className='customized-radio'>
                          No
                          <input
                            type='radio'
                            name='customizable'
                            defaultChecked={product.customizable === false}
                            value={false}
                            onChange={(event) => handleProductChange(event)}
                          />
                          <span className='checkmark'></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-sm-12 col-md-6'>
                    <label className='mb-2'>Do you have a sample ?</label>
                    <div className='row mt-2 px-3'>
                      <div className='d-inline-flex'>
                        <label className='customized-radio'>
                          Yes
                          <input
                            type='radio'
                            name='has_product_sample'
                            defaultChecked={product.has_product_sample === true}
                            value={true}
                            onChange={(event) => handleProductChange(event)}
                          />
                          <span className='checkmark'></span>
                        </label>
                        &nbsp;&nbsp;&nbsp;
                        <label className='customized-radio'>
                          No
                          <input
                            type='radio'
                            name='has_product_sample'
                            defaultChecked={
                              product.has_product_sample === false
                            }
                            value={false}
                            onChange={(event) => handleProductChange(event)}
                          />
                          <span className='checkmark'></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className='col-sm-12 col-md-6'>
                    <label>Product sample price(0 If Free)</label>
                    <input
                      type='number'
                      onChange={(event) => handleNegativeValue(event)}
                      className='form-control'
                      id='samplePrice'
                      name='product_sample_amount'
                      value={product.product_sample_amount}
                      disabled={!product.has_product_sample}
                      placeholder='0 if free'
                    />
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-sm-12 col-md-6'>
                    <label className='mb-2'>
                      Sample product payment requires approval ?
                    </label>
                    <div className='row mt-2 px-3'>
                      <div className='d-inline-flex'>
                        <label className='customized-radio'>
                          Yes
                          <input
                            type='radio'
                            name='product_sample_mustApprove'
                            defaultChecked={
                              product.product_sample_mustApprove === true
                            }
                            value={true}
                            onChange={(event) => handleProductChange(event)}
                          />
                          <span className='checkmark'></span>
                        </label>
                        &nbsp;&nbsp;&nbsp;
                        <label className='customized-radio'>
                          No
                          <input
                            type='radio'
                            name='product_sample_mustApprove'
                            defaultChecked={
                              product.product_sample_mustApprove === false
                            }
                            value={false}
                            onChange={(event) => handleProductChange(event)}
                          />
                          <span className='checkmark'></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}

            <div className='px-4'>
              <div className='row mt-3 custom-form-fields '>
                <div className='col-xl-6 '>
                  {formId && formId !== '' ? (
                    <div className='dynamic_form_for_category'>
                      <div className=''>
                        <div className=''>
                          <div>
                            <div className='col-12'>
                              <h4 className='text-dark'>{formNow.formName}</h4>
                            </div>
                          </div>
                          <div className='col-12'>
                            {formNow.fields.map((item, index) => (
                              <React.Fragment
                                key={
                                  index + '____dynamic_form_loop__' + item.name
                                }
                              >
                                <div className='form-group'>
                                  {item.type === 'text' ? (
                                    <div>
                                      <label>{item.label}</label>{' '}
                                      {item.required ? (
                                        <label className='text-danger'>*</label>
                                      ) : null}
                                      <input
                                        type='text'
                                        className='form-control'
                                        id={item.name}
                                        name={item.name}
                                        onChange={(e) =>
                                          handleCategoryFormChange(item, e)
                                        }
                                        placeholder={item.placeholder}
                                        value={item.value}
                                        required={item.required}
                                        autoComplete='off'
                                      />
                                    </div>
                                  ) : null}

                                  {item.type === 'email' ? (
                                    <div>
                                      <label>{item.label}</label>{' '}
                                      {item.required ? (
                                        <label className='text-danger'>*</label>
                                      ) : null}
                                      <input
                                        type={item.type}
                                        className='form-control'
                                        id={item.name}
                                        name={item.name}
                                        onChange={(e) =>
                                          handleCategoryFormChange(item, e)
                                        }
                                        placeholder={item.placeholder}
                                        value={item.value}
                                        required={item.required}
                                        autoComplete='off'
                                      />
                                    </div>
                                  ) : null}

                                  {item.type === 'number' ? (
                                    <div>
                                      <label>{item.label}</label>{' '}
                                      {item.required ? (
                                        <label className='text-danger'>*</label>
                                      ) : null}
                                      <input
                                        type={item.type}
                                        className='form-control'
                                        id={item.name}
                                        name={item.name}
                                        onChange={(e) =>
                                          handleCategoryFormChange(item, e)
                                        }
                                        min={item.min}
                                        max={item.max}
                                        placeholder={item.placeholder}
                                        value={item.value}
                                        required={item.required}
                                        autoComplete='off'
                                      />
                                    </div>
                                  ) : null}

                                  {item.type === 'date' ? (
                                    <div>
                                      <label>{item.label}</label>{' '}
                                      {item.required ? (
                                        <label className='text-danger'>*</label>
                                      ) : null}
                                      <input
                                        type={item.type}
                                        className='form-control'
                                        id={item.name}
                                        name={item.name}
                                        onChange={(e) =>
                                          handleCategoryFormChange(item, e)
                                        }
                                        placeholder={item.placeholder}
                                        value={item.value}
                                        required={item.required}
                                        autoComplete='off'
                                      />
                                    </div>
                                  ) : null}

                                  {item.type === 'datetime-local' ? (
                                    <div>
                                      <label>{item.label}</label>{' '}
                                      {item.required ? (
                                        <label className='text-danger'>*</label>
                                      ) : null}
                                      <input
                                        type={item.type}
                                        className='form-control'
                                        id={item.name}
                                        name={item.name}
                                        onChange={(e) =>
                                          handleCategoryFormChange(item, e)
                                        }
                                        placeholder={item.placeholder}
                                        value={item.value}
                                        required={item.required}
                                        autoComplete='off'
                                      />
                                    </div>
                                  ) : null}
                                  {item.type === 'file' ? (
                                    <div>
                                      <label>{item.label}</label>{' '}
                                      {item.required ? (
                                        <label className='text-danger'>*</label>
                                      ) : null}
                                      <input
                                        type={item.type}
                                        className='form-control'
                                        id={item.name}
                                        name={item.name}
                                        onChange={(e) =>
                                          handleCategoryFormChange(item, e)
                                        }
                                        placeholder={item.placeholder}
                                        value={item.value}
                                        required={item.required}
                                        autoComplete='off'
                                      />
                                    </div>
                                  ) : null}

                                  {item.type === 'textarea' ? (
                                    <div>
                                      <label>{item.label}</label>{' '}
                                      {item.required ? (
                                        <label className='text-danger'>*</label>
                                      ) : null}
                                      <textarea
                                        className='form-control'
                                        id={item.name}
                                        name={item.name}
                                        onChange={(e) =>
                                          handleCategoryFormChange(item, e)
                                        }
                                        placeholder={item.placeholder}
                                        value={item.value}
                                        required={item.required}
                                        autoComplete='off'
                                      />
                                    </div>
                                  ) : null}

                                  {item.type === 'autocomplete' ? (
                                    <div>
                                      <label>{item.label}</label>{' '}
                                      {item.required ? (
                                        <label className='text-danger'>*</label>
                                      ) : null}
                                      <select
                                        className='form-control'
                                        id={item.name}
                                        name={item.name}
                                        onChange={(e) =>
                                          handleCategoryFormChange(item, e)
                                        }
                                        value={item.value}
                                        required={item.required}
                                      >
                                        {item.values.map((option, index) => (
                                          <option
                                            key={
                                              option.label +
                                              '_dynamic_input_' +
                                              item.name
                                            }
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  ) : null}

                                  {item.type === 'checkbox' ? (
                                    <div>
                                      <label>{item.label}</label>{' '}
                                      {item.required ? (
                                        <label className='text-danger'>*</label>
                                      ) : null}
                                      {item.values.map((check, index) => (
                                        <div
                                          className='inline-form-group'
                                          key={
                                            check.label +
                                            '_dynamic_input_checkbox_' +
                                            item.name
                                          }
                                        >
                                          <label className='cust-check-bx'>
                                            <input
                                              type='checkbox'
                                              value={check.value}
                                              name={item.name}
                                              onChange={(e) =>
                                                handleCategoryFormChange(
                                                  item,
                                                  e
                                                )
                                              }
                                              onClick={() => {
                                                // toggleValue(check)
                                                check.selected = !check.selected;
                                              }}
                                            />{' '}
                                            {check.label}
                                            <span className='checkmark'></span>
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                  ) : null}

                                  {item.type === 'radio' ? (
                                    <div>
                                      <label>{item.label}</label>{' '}
                                      {item.required ? (
                                        <label className='text-danger'>*</label>
                                      ) : null}
                                      {item.values.map((radio, index) => (
                                        <div
                                          className='inline-form-group'
                                          key={
                                            radio.label +
                                            '_dynamic_input_radio_' +
                                            item.name
                                          }
                                        >
                                          <label className='cust-check-bx'>
                                            <input
                                              type='radio'
                                              value={radio.value}
                                              onChange={(e) =>
                                                handleCategoryFormChange(
                                                  item,
                                                  e
                                                )
                                              }
                                              name={item.name}
                                            />{' '}
                                            {radio.label}
                                            <span className='checkmark'></span>
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                  ) : null}
                                </div>
                              </React.Fragment>
                            ))}{' '}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='text-center pt-4'>
                      <span className='text-muted'>
                        No Form Attached to Selected Category!
                      </span>
                    </div>
                  )}
                </div>

                <div className='col-xl-6 '>
                  <p>Input missing Product fields</p>
                  {dynamicForm.map((field, index) => (
                    <div
                      className='row'
                      key={index + '___dynamic__field_value_'}
                    >
                      <div className='col-12 col-md-12 col-lg-5 mt-2'>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Field name'
                          name={'name__' + index}
                          id={'name__' + index}
                          onChange={(e) =>
                            handleDynamicFormChange(field, e, 'name')
                          }
                        />
                      </div>
                      <div className='col-10 col-md-12 col-lg-5 mt-2'>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Field value'
                          name={'value__' + index}
                          id={'value__' + index}
                          onChange={(e) =>
                            handleDynamicFormChange(field, e, 'value')
                          }
                        />
                      </div>
                      <div className='col-2 col-md-12 col-lg-2 mt-2'>
                        <span
                          className='btn btn-sm btn-danger'
                          onClick={() => removeDynamicField(index)}
                        >
                          <i className='fa fa-remove'></i>
                        </span>
                      </div>
                    </div>
                  ))}

                  <div className='row pt-3'>
                    <div className='col-12'>
                      <button
                        className='btn btn-sm btn-info float-right'
                        onClick={() => addNewField()}
                        type='button'
                      >
                        <i className='fa fa-plus'></i>&nbsp;&nbsp;Add More
                        Fields
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row mt-4 px-5'>
              {!loading ? (
                <button
                  className='btn btn-block btn-primary mb-5'
                  disabled={!product.productName || !product.unit_price}
                  type='submit'
                >
                  {props.product._id ? 'Update and Continue' : 'Create Product'}
                </button>
              ) : (
                <button className='btn btn-block btn-primary mb-5'>
                  <i className='fa fa-spin fa-spinner'></i>
                  &nbsp;&nbsp;{props.product._id ? 'Saving...' : 'Updating...'}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductDetails;
