import React, { useState, useEffect, Fragment } from 'reactn';

import '../../../../assets/css/mystore.css';
import { Link } from 'react-router-dom';
import RandomService from '../../../../utils/randomServices';
import ProductService from '../../../../services/product/ProductService';
import StoreService from '../../../../services/store/StoreService';
import defaultImg from '../../../../assets/img/products/default_product_img.png';
import withDashboard from '../../hoc/withDashboard';

import { toast } from 'react-toastify';
// import {useHistory } from "react-router-dom";
import { useParams, useHistory } from 'react-router-dom';
const ListProducts = () => {
  const history = useHistory();

  // const {userId} =  useParams();
  const { storeId } = useParams();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  // const history = useHistory();
  //   state = {
  //     step: 1,
  //     loading: false,
  //     storeCount: 0,
  //     products: [],
  //     store: {},
  //     showCreateBtn: false,
  //   };

  const [storeName, setStoreName] = useState('');
  const getStoreName = async () => {
    const storeService = new StoreService();
    const response = await storeService.getStoreByID(storeId);
    if (response && response.status === 'SUCCESS') {
      setStoreName(response.data.companyName);
    } else {
      toast.error(response.msg || 'Unable to list products!');
    }
  };

  const fetchProducts = async () => {
    setLoading(true);
    const productService = new ProductService();
    const response = await productService.getProducts(storeId);
    if (response && response.status === 'SUCCESS') {
      toast.success('Products listed successfully!');

      setProducts(response.data.data);
      setLoading(false);
      RandomService.initDatatable('list-products');
    } else {
      toast.error(response.msg || 'Unable to list products!');
      RandomService.initDatatable('list-products');
      setLoading(false);
    }
  };

  // const getCategoryName = async (categoryId) =>{
  //     const storeService = new StoreService();
  //     const response = await storeService.getCategoryName(categoryId);
  //     if (response && response.status === 'SUCCESS') {
  //       const name = response.data.categoryName;
  //         return name;
  //     } else {
  //       toast.error(response.msg || 'Unable to list products!');

  //     }
  // }

  // const getStore = async () => {
  //   const currentUser = await RandomService.getAuthUser();
  //   const storeService = new StoreService();
  //   const response = await storeService.getUserStore(userId);
  //   if (response && response.status === 'SUCCESS') {
  //       setStore(response.data[0]);
  //       setShowCreateBtn(true);
  //   } else {
  //   }
  // };

  // const countStores = async () => {
  //   const storeService = new StoreService();
  //   const response = await storeService.countStores(userId);
  //   if (response && response.status === 'SUCCESS') {
  //       setStoreCount(response.data);
  //     if (response.data === 1) {
  //       getStore().then();
  //     }
  //   } else {
  //   }
  // };

  // const editProduct = (product) =>{
  //   history.push('/update-product/' + product._id, product);
  // }
  // const getFilteredProducts = async (filter) => {
  //   setLoading(true);
  //   const productService = new ProductService();
  //   const response = await productService.getFilteredProducts(
  //     userId,
  //     filter
  //   );
  //   if (response && response.status === 'SUCCESS') {
  //     // toast.success(response.msg || "Products listed successfully!");

  //     setProducts(response.data.data);
  //     setLoading(false);
  //     RandomService.initDatatable('list-products');
  //   } else {
  //     toast.error(response.msg || 'Unable to list products!');
  //     RandomService.initDatatable('list-products');
  //     setLoading(false);
  //   }
  // };
  // const filterProducts = (e) => {
  //   e.preventDefault();

  //   const value = e.target.value;
  //   if (value === 'ALL') {
  //     fetchProducts().then();
  //   } else {
  //     getFilteredProducts({ approval_status: value }).then();
  //   }
  // };

  useEffect(() => {
    getStoreName().then();
    fetchProducts().then();
    // eslint-disable-next-line
  }, []);

  const viewProductDetails = (productId) => {
    history.push('/dashboard/products/product-details/' + productId);
  };

  return (
    <Fragment>
      <div className='card px-3 py-5 px-4 list-product'>
        <div>
          <div className='row'>
            <div className='col-sm-12 col-md-4 col-xl-4'>
              <h4>Product List ({storeName})</h4>
            </div>
            <div className='col-sm-12 col-md-4 col-xl-4 mt-5 mt-md-0 mb-md-0 mb-4'>
              <label htmlFor='filterProduct'>Filter Products</label>
              <select className='form-control' id='filterProduct'>
                <option>ALL</option>
                <option>ONLINE</option>
                <option>OFFLINE</option>
                <option>PENDING</option>
                <option>REJECTED</option>
              </select>
            </div>
            <div className=' col-sm-12 col-md-4 col-xl-4 mt-md-4'>
              <Link to={'/dashboard/products/create-product/' + storeId}>
                <button className='m-btn m-btn-primary float-right m-btn-small-round bg-primary'>
                  New Product
                </button>
              </Link>
            </div>
          </div>

          <div>
            <div className='body mt-5'>
              {loading ? (
                <div className='text-center mt-1 mb-3'>
                  <span className='fa fa-spin fa-spinner fa-2x'></span>&nbsp;
                  <br />
                  <span> Loading, Please wait...</span>
                </div>
              ) : null}
              {!loading ? (
                <div className='table-responsive'>
                  <table
                    id='list-products'
                    className='table table-bordered table-hover table-striped dataTable'
                    cellSpacing='0'
                    width='100%'
                  >
                    <thead>
                      <tr>
                        <th className='no-sort'></th>
                        <th>S/N</th>
                        <th></th>
                        <th>Name</th>
                        {/* <th>Model</th>
                          <th>Brand</th> */}
                        <th>Status</th>
                        <th>Category</th>
                        {/* <th>Order Type</th> */}
                        <th>Currency</th>
                        <th>Minimum Order</th>
                        <th>Created</th>

                        <th>Action</th>
                      </tr>
                    </thead>
                    <tfoot>
                      <tr>
                        <th className='no-sort'></th>
                        <th>S/N</th>
                        <th></th>
                        <th>Name</th>
                        {/* <th>Model</th>
                          <th>Brand</th> */}
                        <th>Status</th>
                        <th>Category</th>
                        {/* <th>Order Type</th> */}
                        <th>Currency</th>
                        <th>Minimum Order</th>
                        <th>Created</th>
                        <td>Action</td>
                      </tr>
                    </tfoot>
                    <tbody>
                      {products?.map((product, index) => (
                        <tr
                          key={product._id + '___productItem___' + index}
                          id={product._id}
                        >
                          <td
                            className='details-control'
                            id={'td-' + index}
                          ></td>

                          <td className='text-center'>{index + 1}</td>
                          <td className='text-center'>
                            <img
                              src={product.coverImage || defaultImg}
                              width='50'
                              alt=''
                            />
                          </td>
                          <td className='text-center'>
                            {product?.productName}
                          </td>
                          {/* <td className='text-center'>{product.modelName}</td>
                            <td>{product.brandName}</td> */}
                          <td>
                            {product?.approval_status?.toLowerCase() ===
                            'approved' ? (
                              <span className='badge badge-pill badge-success'>
                                {product?.approval_status}
                              </span>
                            ): (null)}

                            {product?.approval_status?.toLowerCase() ===
                            'pending' ? 
                            (
                              <span className='badge badge-pill badge-info'>
                                {product?.approval_status}
                              </span>
                            ): (null)}


                          {product?.approval_status?.toLowerCase() ===
                            'rejected' ? 
                            (
                              <span className='badge badge-pill badge-danger'>
                                {product?.approval_status}
                              </span>
                            ): (null)}

                          </td>
                          <td>{product?.categoryId?.categoryName}</td>
                          {/* <td>{product.orderTypeId.name}</td> */}
                          <td>{product.currency}</td>

                          <td>{product?.minOrder}</td>

                          {/*<td><small dangerouslySetInnerHTML={{__html: product.productSpecification}}></small></td>*/}
                          <td>{product?.createdAt}</td>
                          <td>
                            <div className='btn-group'>
                              <button type='button' className='btn btn-success'>
                                Action
                              </button>
                              <button
                                type='button'
                                className='btn btn-success dropdown-toggle dropdown-toggle-split'
                                data-toggle='dropdown'
                                aria-haspopup='true'
                                aria-expanded='false'
                              >
                                <span className='sr-only'>Toggle Dropdown</span>
                              </button>
                              <div className='dropdown-menu dropdown-menu-right'>
                                <a
                                  className='dropdown-item h4'
                                  href='#!'
                                  onClick={() =>
                                    viewProductDetails(product._id)
                                  }
                                >
                                  View Product
                                </a>
                                <a className='dropdown-item h4' href='#!'>
                                  Disable
                                </a>

                                {/*                                     
                              <div class="dropdown-divider"></div>
                              <a class="dropdown-item" href="#!">Disable</a> */}
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// export default ListProducts;

export default withDashboard(ListProducts);
