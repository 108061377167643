import React , {useState,useEffect} from 'react';
// import { Link } from 'react-router-dom';
import withDashboard from '../../hoc/withDashboard';
import {toast} from 'react-toastify';
import Loader from "react-loader-spinner";
import OrderService from '../../../../services/order/OrderService';

export const InvoiceOverview = () => {
 
  const [invoices, setInvoices] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchInvoiceCount = async () =>{
    setLoading(true);

  const orderService = new OrderService();
  const response = await orderService.getInvoiceCount();

    try {
    if (response && response.status === 'SUCCESS'){
        setInvoices(response.data);
        setLoading(false);
    } else {
      setLoading(false);
    }
  } catch (error) {
    toast.error(error.message );
  }
};

const [paidInvoices, setPaidInvoices] = useState("");

  const fetchPaidInvoicesCount = async () =>{
    setLoading(true);

    const orderService = new OrderService();
    const response = await orderService.getInvoiceCountBasedOnStatus("PAID");

    try {
    if (response && response.status === 'SUCCESS'){
        setPaidInvoices(response.data);
    } else {
      toast.error(response.msg);
    }
  } catch (error) {
    toast.error(error.message);
  }
};


const [declinedInvoices, setDeclinedInvoices] = useState("");

  const fetchDeclinedInvoicesCount = async () =>{
    setLoading(true);

    const orderService = new OrderService();
    const response = await orderService.getInvoiceCountBasedOnStatus("REJECTED");

    try {
    if (response && response.status === 'SUCCESS'){
        setDeclinedInvoices(response.data);
    } else {
      toast.error(response.msg);
    }
  } catch (error) {
    toast.error(error.message);
  }
};


const [pendingInvoices, setPendingInvoices] = useState("");

  const fetchPendingInvoicesCount = async () =>{
    setLoading(true);

    const orderService = new OrderService();
    const response = await orderService.getInvoiceCountBasedOnStatus("PENDING");

    try {
    if (response && response.status === 'SUCCESS'){
        setPendingInvoices(response.data);
    } else {
      toast.error(response.msg);
    }
  } catch (error) {
    toast.error(error.message);
  }
};


const [totalSales, setTotalSales] = useState("");

  const fetchTotalSales = async () =>{
    setLoading(true);

    const orderService = new OrderService();
    const response = await orderService.getTotalSales();

    try {
    if (response && response.status === 'SUCCESS'){
        setTotalSales(response.data);
    } else {
      toast.error(response.msg);
    }
  } catch (error) {
    toast.error(error.message );
  }
};


// Statistics

const [paidStats, setPaidStats] = useState("");

  const fetchPaidStats = async () =>{
    setLoading(true);

    const orderService = new OrderService();
    const response = await orderService.getPaidInvoiceStats();

    try {
    if (response && response.status === 'SUCCESS'){
        setPaidStats(response.data);
    } else {
      toast.error(response.msg);
    }
  } catch (error) {
    toast.error(error.message );
  }
};

const [pendingStats, setPendingStats] = useState("");

  const fetchPendingStats = async () =>{
    setLoading(true);

    const orderService = new OrderService();
    const response = await orderService.getPendingInvoiceStats();

    try {
    if (response && response.status === 'SUCCESS'){
        setPendingStats(response.data);
    } else {
      toast.error(response.msg);
    }
  } catch (error) {
    toast.error(error.message );
  }
};


const [declinedStats, setDeclinedStats] = useState("");

  const fetchDeclinedStats = async () =>{
    setLoading(true);

    const orderService = new OrderService();
    const response = await orderService.getDeclinedInvoiceStats();

    try {
    if (response && response.status === 'SUCCESS'){
        setDeclinedStats(response.data);
    } else {
      toast.error(response.msg);
    }
  } catch (error) {
    toast.error(error.message );
  }
};

useEffect(() => {
  fetchInvoiceCount().then();
  fetchPaidInvoicesCount().then();
  fetchDeclinedInvoicesCount().then();
  fetchPendingInvoicesCount().then();
  fetchTotalSales().then();
  fetchPaidStats().then();
  fetchPendingStats().then();
  fetchDeclinedStats().then();

}, []);



  return (
    <div className="dashboard">
     
     {!loading? (
       
        <div className="card p-5">

        <h3 className="h3 text-dark mb-5">Min.ng admin</h3>
        <div className="overview">
            <h3 className="text-dark ml-n4 mb-5">Invoice Overview</h3>
          <div className="row">
              <div className="col-md-2 mr-5 mt-3 m-single click">
                <div className="d-flex">
                    <div className="report">
                        <p>All invoices</p>
                        <p><b>{invoices}</b></p>
                    </div>
                </div>
              </div>

              <div className="col-md-2 mr-5 mt-3 m-single">
                <div className="d-flex">
                    <div className="report">
                        <p>Paid invoice</p>
                        <p><b>{paidInvoices}</b></p>
                    </div>
                </div>
              </div>

              <div className="col-md-2 mr-5 mt-3 m-single">
                <div className="d-flex">
                    <div className="report">
                        <p>Declined invoices </p>
                        <p><b>{declinedInvoices}</b></p>
                    </div>
                </div>
              </div>
            
              <div className="col-md-2 mr-5 mt-3 m-single">
                <div className="d-flex">
                    <div className="report">
                        <p>Pending invoices </p>
                        <p><b>{pendingInvoices}</b></p>
                    </div>
                </div>
              </div>

              <div className="col-md-2 mr-5 mt-3 m-single">
                <div className="d-flex">
                    <div className="report">
                        <p>Total sales </p>
                        <p><b>{totalSales}</b></p>
                    </div>
                </div>
              </div>
            

     
          
    
     
  </div>

  <div className="invoice-statistics">
  <h3 className="text-dark ml-n4 mt-5 ">Invoice Statistics</h3>
      <p>Paid ({Math.round(paidStats)} %)</p>
      <p>Declined ({Math.round(declinedStats)} %)</p>
      <p>Pending ({Math.round(pendingStats)} %)</p>
  </div>


</div>


</div>
     ):(
              <Loader
              type="BallTriangle"
              height={100}
              width={100}
              timeout={3000} //3 secs
              className="loader"
              /> 
     )}
      
    </div>
  );
};

export default withDashboard(InvoiceOverview);