import axios from "axios";

// Note all apiService call accepts url and optional data

export default class UserService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_INVENTORY_BACK;
  }

  createCategory = async (data) => {
    try {
      const response = await axios.post(`${this.baseUrl}/category`, data);
      return response.data;
    } catch (error) {
      return error;
    }
  };
  findCategoryByParent = async (parentId) => {
    try {
      const response = await axios.get(
        `${this.baseUrl}/category/parent/${parentId}`
      );
      return response.data;
    } catch (error) {
      return error;
    }
  };
  deleteCategory = async (id, parentCategory) => {
    try {
      const response = await axios.delete(
        `${this.baseUrl}/category/${id}/${parentCategory}`
      );
      return response.data;
    } catch (error) {
      return error;
    }
  };
  findCategoryByParentAndname = async (parentId, parentCategoryname) => {
    try {
      const response = await axios.get(
        `${this.baseUrl}/category/parent/${parentId}/${parentCategoryname}`
      );
      return response.data;
    } catch (error) {
      return error;
    }
  };
  updateCategory = async (id, data) => {
    try {
      const response = await axios.patch(
        `${this.baseUrl}/category/${id}`,
        data
      );
      return response.data;
    } catch (error) {
      return error;
    }
  };
}
