import React, { Fragment} from 'react';

// import {createUseStyles} from 'react-jss';
// import { Form, Formik } from 'formik';

// import FormikControl from '../../../common/formik/FormikControl';

// import avatar from '../../../../assets/img/accountSetup/avatar.png'
// import { Link } from 'react-router-dom';

// import Select from "react-select";
import RandomService from "../../../../utils/randomServices";
import ChangePassword from "./ChangePassword";
import withDashboard from '../../hoc/withDashboard';
// import StoreService from "../../../../services/store/StoreService";

// import OrderService from "../../../../../services/store/OrderService";



const MySettings = () => {

  
    async function getCurrentUser() {
        const user = await RandomService.getAuthUser();
        // setCurrentUser(user.email);

        return user;

    }

      React.useMemo(
        () => {
          getCurrentUser().then();

        

        },
        // eslint-disable-next-line
        []
      );

    //   const [loginUser] = React.useState(JSON.parse(sessionStorage.getItem(process.env.REACT_APP_USER_MEMO)));

    //   const initialValues = {
    //     fname:loginUser.firstName,
    //     lname:loginUser.lastName,
    //     phone:loginUser.phoneNumber,
    //     email:loginUser.email,
    //     country:loginUser.country,
    //     state:loginUser.state,
    //     city:loginUser.city,
    //     postal:loginUser.postalCode,
    //     address:loginUser.address
    //   };

    //   const sub = (values)=>{
    //     console.log(values);
    //   }
    
        return (
            <Fragment>
                <div className="card p-3 mt-4 pb-5 settings">
                <div className='my-order'>
                    <div className='row p-4'>
                        <div className='col-6 col-md-9 col-xl-9'>
                            <p className='head h2'>Settings</p>
                        </div>
                        <div className=' col-6 col-md-3 col-xl-3'>

                        </div>
                    </div>

                    {/* profile */}

                    {/* <div className="profile-pic-container">
                            <label className="profile-pic" htmlFor="file">
                                    <img src={image} alt=""  className="rounded-circle" width="150" height="150" for />
                            </label>
                            <input type="file" id="file" className="d-none" onChange={handleProfile}/>

                            {!isempty? (
                                <div>

                                <button className='btn btn-primary  mt-3  rounded' >Change image</button>

                                </div>

                            ): null
                            }                            
                        
                            
                    </div> */}


                    
                     

{/* account */}
                    {/* <div className="px-4 mb-5"> */}
                        {/* <Formik initialValues={initialValues} onSubmit={(values) =>{sub(values)}} >
                            <Form>
                               <div className="row mt-5 px-4">
                                   <div className="col-md-5">
                                        <FormikControl
                                            control='input'
                                            name= 'fname'
                                            label='First Name'
                                            placeholder='Search...'
                                            />

                                   </div>
                                   <div className="col-md-5">
                                        <FormikControl
                                            control='input'
                                            name= 'lname'
                                            label='Last Name'
                                            placeholder='Search...'
                                            
                                            />
                                   </div>
                               </div>

                                <div className="row px-4">
                                    <div className="col-md-5">
                                        <FormikControl
                                                control='input'
                                                name= 'email'
                                                type='email'
                                                label='Email Address'
                                                placeholder='Search...'
                                                />
                                    </div>
                                    <div className="col-md-5">
                                         <FormikControl
                                                control='input'
                                                name= 'phone'
                                                label='Phone Number'
                                                placeholder='Search...'
                                                />
                                    </div>
                                </div>
                               <div className="col-md-10">
                             

                                <FormikControl
                                                control='select'
                                                name= 'country'
                                                label='Country/Region'
                                                placeholder='Search...'
                                                >
                                                
                                                    <option className='grey' value=''>
                                                        Select
                                                    </option>
                                    </FormikControl>
                               </div>

                            
                              
                               
                               <div className="row px-4 mt-3">
                                   <div className="col-md-5">
                                        <FormikControl
                                            control='input'
                                            name= 'state'
                                            label='Your State'
                                            placeholder='Search...'
                                            />
                                   </div>
                                   <div className="col-md-5">
                                        <FormikControl
                                            control='input'
                                            name= 'city'
                                            label='Your City'
                                            placeholder='Search...'
                                            />

                                   </div>
                               </div>

                                <div className="col-md-10">
                                     <FormikControl
                                            control='input'
                                            name= 'postal'
                                            label='Postal Code'
                                            placeholder='Search...'
                                            />

                                    <FormikControl
                                            control='input'
                                            name= 'address'
                                            label='Address'
                                            placeholder='Search...'
                                            />

                                    <button
                                    type='submit'
                                    className='btn btn-primary float-right mt-3  rounded'
                                    >
                                    Update
                                    </button>
                                </div>
                               
                                
                            </Form>
                        </Formik> */}
{/* </div> */}
                        

{/* password reset */}
<ChangePassword/>
                    </div>
                
            </div>
            </Fragment>
        );
   
}

// export default ListOrders;

export default withDashboard(MySettings);
