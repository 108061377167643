// import * as firebase from 'firebase';
import { toast } from 'react-toastify';
import firebase, { firebaseStorage } from '../configs/firebase.config';

const basePath = '/minng_files';

export default {
  uploadB64(upload, dataObject, fileName, callback, key) {
    const storageRef = firebaseStorage.ref();
    const itemForUpload = upload;
    const name = '_image_' + Math.random() * 100 + '_' + fileName;
    const uploadBase = itemForUpload.split(';base64,')[1];
    const uploadTask = storageRef
      .child(`${basePath}/${name}`)
      .putString(uploadBase, 'base64');
    // split out the image/png or jpg out of string
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        // upload in progress
        // upload.progress = (uploadTask.snapshot.bytesTransferred / uploadTask.snapshot.totalBytes) * 100;
      },
      (error) => {
        // upload failed
      },
      () => {
        // upload success
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          // upload success
          // upload.url = uploadTask.snapshot.downloadURL;
          dataObject[key] = downloadURL;
          // upload.url = downloadURL;
          callback(dataObject);
          // upload.name = upload.file.name;
        });
      }
    );
  },
  async uploadBlob(upload, dataObject, callback, key) {
    const storageRef = firebaseStorage.ref();
    const uploadTask = storageRef
      .child(`${basePath}/${upload.name}`)
      .put(upload);
    await uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        // upload in progress
        // upload.progress = (uploadTask.snapshot.bytesTransferred / uploadTask.snapshot.totalBytes) * 100;
      },
      (error) => {
        // upload failed

        toast.error('Unable to upload file!');
      },
      () => {
        // upload success
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          // upload success
          // upload.url = uploadTask.snapshot.downloadURL;
          dataObject[key] = downloadURL;
          upload.url = downloadURL;
          callback(dataObject);
          // upload.name = upload.file.name;
        });
      }
    );
  },
  uploadMultiple(upload, path) {
    return new Promise((resolve, reject) => {
      const storageRef = firebaseStorage.ref();
      const uploadTask = storageRef
        .child(`${basePath}/${path}/${upload.name}`)
        .put(upload);
      uploadTask.on(
        'state_changed',
        function progress(snapshot) {},
        function error(err) {
          reject({});
        },
        function complete() {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            // upload.url = downloadURL;
            resolve({ name: upload.name, url: downloadURL });
          });
        }
      );
    });
  },
  uploadOnEditor(upload, uploadPath, callback) {
    const storageRef = firebaseStorage.ref();
    const itemForUpload = upload;
    const uploadTask = storageRef
      .child(`${basePath}/${uploadPath}/${upload.name}`)
      .put(itemForUpload);
    // split out the image/png or jpg out of string
    uploadTask.on(
      firebaseStorage.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        // upload in progress
        // upload.progress = (uploadTask.snapshot.bytesTransferred / uploadTask.snapshot.totalBytes) * 100;
      },
      (error) => {
        // upload failed
        toast.error('Unable to upload to file, check internet connection!');
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          // upload success
          // upload.url = uploadTask.snapshot.downloadURL;
          upload.url = downloadURL;
          // upload.name = upload.file.name;
          callback(upload.url);
        });
      }
    );
  },
};
