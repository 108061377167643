import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import successImg from '../../../assets/img/Success image.png';
import { Link } from 'react-router-dom';

const SuccessPage = (props) => {
  const { title, text, link, linkLabel, nextStep } = props;
  return (
    <div className='container'>
      <div className='card card-store' id='multi-form-card'>
        <div className='card-header d-none d-sm-none d-md-block d-lg-block'>
          <div className='row'>
            <div className='col-sm-12'>
              <h3 className='txt-primary .card-header'>{title && title}</h3>
            </div>
          </div>
        </div>
        <div className='form-content-container'>
          <div className='row'>
            <div className='col-sm-2 d-none d-sm-none d-md-block'></div>

            <div className='col-sm-12 col-md-8'>
              <div className=' w-100'>
                <div className='row'>
                  <div className='col-lg-3'></div>
                  <div className='col-lg-6'>
                    <img src={successImg} className='card-img-top' alt='' />
                  </div>
                  <div className='col-lg-3'></div>
                </div>
                <div className='card-body'>
                  <h4
                    className='card-title text-center'
                    style={{
                      marginTop: '-1.8em',
                      textAlign: 'center',
                      color: '#000',
                      fontWeight: '600',
                      paddingRight: '1.8em',
                    }}
                  >
                    Great job!
                  </h4>
                  <p className='card-text text-center'>{text && text}</p>
                </div>
              </div>
              <div className='row'>
                {nextStep ? (
                  <Fragment>
                    <div className='col-sm-6 mb-4'>
                      <Link to={`/${link}`}>
                        <button className='btn btn-primary btn-block'>
                          {linkLabel && linkLabel}
                        </button>
                      </Link>
                    </div>
                    <div className='col-sm-6 mb-4'>
                      <button
                        className='btn btn-info btn-block'
                        style={{ paddingTop: '12px', paddingBottom: '12px' }}
                      >
                        Proceed to Next Step
                      </button>
                    </div>
                  </Fragment>
                ) : (
                  <div className='col-sm-12 mb-4'>
                    <Link to={link && `/${link}`}>
                      <button
                        className='btn btn-primary btn-block'
                        style={{ padding: '12px' }}
                      >
                        {linkLabel && linkLabel}
                      </button>
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div className='col-sm-2 d-none d-sm-none d-md-block'></div>
          </div>
        </div>
      </div>
    </div>
  );
};

SuccessPage.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  linkLabel: PropTypes.string.isRequired,
  nextStep: PropTypes.bool,
};

export default SuccessPage;
