import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";

const Input = (props) => {
  const { name, label, ...rest } = props;
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <Field
        name={name}
        autoComplete="off"
        className="form-control"
        {...rest}
      />
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default Input;
