import * as jwt from "jsonwebtoken";

/**
 * scrambles string data
 * @param {String} token - input string data
 * @returns {String} - scrambled data
 */
const shuffleToken = (token) => {
    if (token) {
        return token.split("").reverse().join("");
    } else return '';
}

/**
 * Class representing the Authentication methods
 * @class Authentication
 * @description Authentication class methods
 */

class Authentication {
    /**
     * verify a token's validity
     * @param {string} token - token input
     * @returns {req} - populate the request with the decrypted content
     */
    async verifyToken(token) {
        const reshuffledToken = shuffleToken(token);
        let output = {};
        return jwt.verify(
            reshuffledToken,
            process.env.REACT_APP_JWT_SECRET,
            (err, decoded) => {
                if (err) {
                    output = {
                        Error: "Failed to authenticate token",
                        success: false,
                    };
                } else {
                    // console.log('Decoded ', decoded);
                    output = { success: true, ...decoded };
                }
                return output;
            }
        );
    }
}

export default Authentication;